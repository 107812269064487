import axios from "../config/axios";
const billing = {
    addCreditCard: ( data ) =>
        axios
        .post(`/billing/Billing/addCreditCard`,data)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
    delCreditCard: (data) =>
        axios
        .post(`/billing/Billing/delCreditCard`, data)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
    makePrimeCard: (data) =>
        axios
        .post(`/billing/Billing/primeCreditCard`, data)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
    calcelSubscription: (data) =>
        axios
        .post(`/billing/Billing/cancel_user_subscription`, data)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
    GetUserBillingInfo: (data) =>
        axios
        .post(`/billing/Billing/getUserBillingInfo`,data)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
    GetAllInvoices: (data) =>
        axios
            .post(`/billing/Billing/getMonthlyInvoiceReport`,data)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
    GetInvoiceDetail: (data) =>
        axios
            .post(`/billing/Billing/getInvoiceFullDetail`,data)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
    GetInvoices: ( data ) => 
        axios
        .post(`/billing/Billing/get_Invoices`,data)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
    GetMonthlyRengaged : ( data ) => 
        axios
        .post(`/billing/Billing/getMonthlyExpenseReport`,data)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
    GetMonthlyvoiceUsage : ( data ) => 
            axios
            .post(`/billing/Billing/getMonthlyvoiceUsage`,data)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),
    ExportBillingCsv : ( data ) => 
        axios
        .post(`/billing/Billing/exportBillingCsv`,data)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
    GetUserCards : ( data ) => 
        axios
        .post(`/billing/Billing/getUserCards`,data)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),    
    GetUserBillingDetail : ( data ) => 
        axios
        .post(`/billing/Billing/user_billing_overview`,data)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)),
    GetUserLinktoken : ( data ) => 
        axios
        .post(`/billing/Billing/generateUserLink`,data)
        .then((resp) => resp.data)
        .catch((err) => console.log(err)), 
    
        fetchzoholink : ( data ) => 
            axios
            .post(`/billing/Billing/fetchzoholink`,data)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)), 
            
        getZohoUserCards : ( data ) => 
            axios
            .post(`/billing/Billing/getZohoUserCards`,data)
            .then((resp) => resp.data)
            .catch((err) => console.log(err)),     
            
} 

export default billing;