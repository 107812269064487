import React from "react";

import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Loader, Message } from "semantic-ui-react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../store/Page";
import api from "../../../../api";
import Modal from "../../ChatSetup/components/Modal";
import CustomErrorField from "../../../Form/CustomErrorField";
import DuplicatePopup from "./DuplicatePopup";
import AddWorkspace from "./AddWorkspace";
import DialogBox from "./DialogBox";
import "./dialog.css"
import constant from "../../../../util_funs/constant"

class BotBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      list: [],
      update_list: [],
      modalstatus: false,
      modalfooter: "",
      modaltitle: "",
      modalbody: "",
      rename: "",
      keyauto_id: "",
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      default_web_wokspace: "",
      default_sms_wokspace: "",
      default_fb_wokspace: "",
      default_gdf_wokspace: "",
      default_voice_wokspace: "",
      default_general_web_wokspace: "",
      default_general_voice_wokspace: "",
      search_val: "",
      workspace_type: "",
      addshow: false,
      duplicateloading: false,
      alertMsg:false,
	  alertMsgLength:false,
      saveBtnDisabled:false,
    };
  }
  componentDidMount() {
    const { setPageTitle, toggleNavigation, toggleBreadcrum } = this.props;
    setPageTitle({
      title: "Workspaces",
      navigationName: "workspace",
      subHeaderTitle: "Conversations",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    this.loadData();
  }

  loadData = () => {
    this.setState({ loading: true });
    api.botbuilder.getLists({bot_type:'GDF'}).then((data) => {
      try {
        this.setState(
          {
            loading: false,
            list: data.data.list,
            update_list: data.data.updated_array,
            default_web_wokspace:
              data.data.default_workspace.default_workspace_id,
            default_sms_wokspace:
              data.data.default_workspace.default_sms_workspace_id,
            default_fb_wokspace:
              data.data.default_workspace.default_fb_workspace_id,
            default_gdf_wokspace:
              data.data.default_workspace.default_gdf_workspace_id,
            default_voice_wokspace:
              data.data.default_workspace.default_voice_workspace_id,
              default_gen_web_wokspace:
              data.data.default_workspace.default_general_web_workspace,
              default_gen_voice_wokspace:
              data.data.default_workspace.default_general_voice_workspace,
              voice_turbo_default_workspace_id:
              data.data.default_workspace.voice_turbo_default_workspace_id,
              allSquadAssistant: data.data.allSquadAssistant
          },
          function () {
            this.setState({
              addworkspace: (
                <AddWorkspace
                  list={this.state.list}
                  history={this.props.history}
                  toggleModal={this.toggleModal}
                />
              ),
            });

            data.data.updated_array.forEach((element, index) => {
              element["parent_id"] = data.data.parent_id;
              api.botbuilder.updateWorkspace(element).then((data) => {
                try {
                  let updated_temp = this.state.update_list;
                  updated_temp[index].loading = false;
                  let list_data = this.state.list;

                  let recordid = list_data.findIndex(
                    (list_data) => list_data.wid == element.auto_id
                  );

                  list_data[recordid]["name"] = data["name"];

                  this.setState({
                    update_list: updated_temp,
                    list: list_data,
                  });
                } catch (err) {
                  console.log(err);
                }
              });
            });
          }
        );

        // check if y
        if (data.data.delete_array.length > 0) {
          api.botbuilder
            .deleteworkspace({ delete_ids: data.data.delete_array })
            .then((data) => {
              try {
              } catch (err) {
                console.log(err);
              }
            });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  loadSerachData = (params) => {
    this.setState({ loading: true });

    api.botbuilder.getSearchList(params).then((data) => {
      try {
        this.setState({
          loading: false,
          list: data.data.list,
        });
        // check if y
      } catch (err) {
        console.log(err);
      }
    });
  };

  renameWorkspace = (auto_id) => {
    let list_data = this.state.list;
    let recordid = list_data.findIndex((list_data) => list_data.wid == auto_id);

    this.setState(
      {
        rename: list_data[recordid]["name"],
        modalstatus: true,
        modalfooter: this.modalFooterRename(auto_id),
        modaltitle: "Rename Workspace",
        keyauto_id: auto_id,
      },
      function () {
        let modalbody = this.modalbodyRename();

        this.setState({ modalbody: modalbody });
      }
    );
  };

  modalbodyRename = () => {
    const { rename} = this.state;
    return (
      <div className="col-lg-12 col-md-12 col-sm-12">
        <label className="form-control-label">
          Title <span>*</span>
        </label>
        <input
          type="text"
          className="form-control"
          key={this.state.keyauto_id}
          placeholder="Enter Summary"
          value={this.state.rename}
          onChange={(e) => this.onRenameChange(e,this.state.keyauto_id)}
        />
        {this.state.alertMsg && <div class="red">This field is required</div>}
		{this.state.alertMsgLength && <div class="red">Workspace name should not exceed 40 characters.</div>}

        <CustomErrorField
          message="This field is required"
          id="help-error"
          className="error invalid-feedback"
        />
      </div>
    );
  };

  onRenameChange = (e,auto_id) => {
	if (e.target.value.length > 40) {
		this.setState({ 
			rename: e.target.value,
			alertMsgLength:true,
			saveBtnDisabled:true
		   },
		   function () {
			let modalbody = this.modalbodyRename();
			let modalFooterbody = this.modalFooterRename(auto_id);
			this.setState({ 
			  modalbody: modalbody,
			  modalfooter: modalFooterbody
			 });
		});
        return;
    }
	
    this.setState({ 
      rename: e.target.value,
      alertMsg:false,
	  alertMsgLength:false,
      saveBtnDisabled:false
     },
     function () {
      let modalbody = this.modalbodyRename();
      let modalFooterbody = this.modalFooterRename(auto_id);
      this.setState({ 
        modalbody: modalbody,
        modalfooter: modalFooterbody
       });
    });
  };

  modalFooterRename = (auto_id) => {
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => this.modalClose()}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>Cancel </span>
        </button>
        <button
          type="button"
          disabled={this.state.saveBtnDisabled}
          onClick={() => this.updateWorkspaceName(auto_id)}
          className="btn linear-gradient yr-submitbtn btn-sm"
        >
          <span>Save</span>
        </button>
      </div>
    );
  };

  modalFooterDelete = (auto_id, show_woksace) => {
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => this.modalClose()}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>{show_woksace ? "No" : "Cancel"} </span>
        </button>
        {show_woksace && (
          <button
            type="button"
            onClick={() => this.confirmDeleteWorkspace(auto_id)}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Yes</span>
          </button>
        )}
      </div>
    );
  };


  updateWorkspaceName = (auto_id) => {
    console.log(this.state.keyauto_id)
    this.setState({
      nameChangeAuto_id:auto_id
    })
    if(this.state.rename.trim() == ''){
      this.setState({
        alertMsg:true,
        saveBtnDisabled:true,
      },
      function () {
        let modalFooterBody = this.modalFooterRename(auto_id);
        let modalbody = this.modalbodyRename();
        this.setState({ modalfooter: modalFooterBody });
        this.setState({ modalbody: modalbody });
      })
      return;
    }else{
      this.setState({
        saveBtnDisabled:true,
      },
      function () {
        let modalFooterBody = this.modalFooterRename(auto_id);
        this.setState({ modalfooter: modalFooterBody });
      })
    }
    api.botbuilder
      .updateWorkspaceName({ auto_id: auto_id, name: this.state.rename.trim() })
      .then((data) => {
        try {
          let list_data = this.state.list;
          let recordid = list_data.findIndex(
            (list_data) => list_data.wid == auto_id
          );

          list_data[recordid]["name"] = data.data["name"];

          this.setState({
            list: list_data,
            userToaster: true,
            userToasterMsg: data.message,
            userToasterColor: "green",
            saveBtnDisabled:false,
          });
          this.modalClose();
          setTimeout(() => {
            this.setState({ userToaster: false });
          }, 3000);
        } catch (err) {
          console.log(err);
        }
      });
  };

  deleteWorkspace = (auto_id) => {
    let defaultlist = [
      this.state.default_web_wokspace,
      this.state.default_sms_wokspace,
      this.state.default_fb_wokspace,
      this.state.default_gdf_wokspace,
      this.state.default_voice_wokspace,
      this.state.default_general_web_wokspace,
      this.state.default_general_voice_wokspace
    ];

    let footer = true;
    let text = "Are you sure you want to delete this workspace ?";
    if (defaultlist.indexOf(auto_id) !== -1) {
      footer = false;
      text = "You can not delete this workspace because its default workspace";
    }
    this.setState({
      modalstatus: true,
      modalfooter: this.modalFooterDelete(auto_id, footer),
      modaltitle: "Confirm your action",
      modalbody: text,
    });
  };

  confirmDeleteWorkspace = (auto_id) => {
    this.setState({ loading: true });
    this.modalClose();
    api.botbuilder.deleteWorkspace({ auto_id: auto_id }).then((data) => {
      try {
        let list_data = this.state.list;
        let recordid = list_data.findIndex(
          (list_data) => list_data.wid == auto_id
        );
        list_data.splice(recordid, 1);
        this.setState({
          list: list_data,
          userToaster: true,
          userToasterMsg: data.message,
          userToasterColor: "green",
          loading: false,
        });

        setTimeout(() => {
          this.setState({ userToaster: false });
        }, 3000);
      } catch (err) {
        console.log(err);
      }
    });
  };

  viewDuplcateScreen = (auto_id, type) => {
    this.setState({
      modalstatus: true,
      modalfooter: "",
      modaltitle: "Conversation type:",
      keyauto_id: auto_id,
      modalbody: (
        <DuplicatePopup
          type={type}
          auto_id={auto_id}
          duplicateWorkspace={this.duplicateWorkspace}
          modalClose={this.modalClose}
        />
      ),
    });
  };

  duplicateWorkspace = (auto_id, type, callback) => {
    api.botbuilder
      .duplicateWorkspace({ auto_id: auto_id, bottype: type })
      .then((data) => {
        try {
          let color = "green";
          // let list_data = this.state.list;
          if (data.status == false) {
            color = "red";
          } else {
            // let list_data = this.state.list;

            // list_data.push(data.data.list);
          }
          this.loadData();

          this.setState({
            // list: list_data,
            userToaster: true,
            userToasterMsg: data.message,
            userToasterColor: color,
            loading: false,
          });
          callback("success");
          this.modalClose();

          setTimeout(() => {
            this.setState({ userToaster: false });
          }, 3000);
        } catch (err) {
          console.log(err);
        }
      });
  };

  modalClose = () => {
    this.setState({
      alertMsg:false,
      saveBtnDisabled:false,
      modalstatus: false,
    },
    function () {
      let modalFooterBody = this.modalFooterRename(this.state.keyauto_id);
      this.setState({ modalfooter: modalFooterBody });
    });
  };

  changeBottype = (e) => {
    console.log( e.target.value );
    this.setState(
      {
        workspace_type: e.target.value,
      },
      function () {
        this.loadSerachData({ workspace_type: this.state.workspace_type });
      }
    );
  };

  submitSearch = (e) => {
    e.preventDefault();
    this.loadSerachData({ search_val: this.state.search_val,workspace_type:this.state.workspace_type });
  };
  chageSearchval = (e) => {
    this.setState({
      search_val: e.target.value,
    });
  };

  addWorkspace = () => {
    let params = {};

    api.botbuilder.getLists(params).then((data) => {
      try {
        let list = data.data.list;
        this.setState({
          addshow: true,
          addworkspace: (
            <AddWorkspace
              list={list}
              history={this.props.history}
              toggleModal={this.toggleModal}
            />
          ),
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  toggleModal = () => {
    this.setState({
      addshow: false,
      addworkspace: "",
    });
  };

  openWorkspace = (code) => {
    this.props.history.push("/botbuilder/" + code);
  };

  render() {
    const { update_list, loading } = this.state;
    const default_list = [
      this.state.default_fb_wokspace,
      this.state.default_sms_wokspace,
      this.state.default_web_wokspace,
      this.state.default_gdf_wokspace,
      this.state.default_voice_wokspace,
      this.state.default_gen_web_wokspace,
      this.state.default_gen_voice_wokspace,
      this.state.voice_turbo_default_workspace_id
    ];
    return (
      <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid">
            <div className="yr-body-p botbuilderdetails">
              <div class="kt-portlet kt-portlet--tabs yr-mt-20 shadow-none">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-toolbar">
                    <ul
                      className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <Link to="/botbuilder" className={"nav-link active"}>
                          <span className="kt-menu__link-text">Workspaces</span>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/automation" className={"nav-link "}>
                          <span className="kt-menu__link-text">
                            Legacy conversations
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="wsab-flex">
                <div
                  className="alert workspace-note upper_comp_div"
                  role="alert"
                >
                  <div>
                    <form onSubmit={(e) => this.submitSearch(e)} method="post">
                      <div className="kt-input-icon kt-input-icon--right header-search kt-input-icon--right">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          id="workspace_type"
                          onChange={(e) => this.chageSearchval(e)}
                          name="workspace_type"
                        />
                        <span className="kt-input-icon__icon kt-input-icon__icon--right">
                          <span>
                            <i className="la la-search"></i>
                          </span>
                        </span>
                      </div>
                    </form>
                  </div>
                  <select
                    className="form-control"
                    style={{ marginLeft: "30px", width: "250px" }}
                    onChange={(e) => this.changeBottype(e)}
                  >
                    <option value="">Select</option>
                    <option value="Web">Web</option>
                    <option value="Facebook">Facebook</option>
                    <option value="INBOUND">Inbound SMS automation</option>
                    <option value="GDF">Google My Business</option>
                    {/* <option value="SMS">Outbound SMS automation</option>
                    <option value="EMAIL">Email automation</option> */}
                    <option value="Voice">Voice</option>
                    <option value="Voice_Turbo">Turbo Voice</option>
                    <option value="GEN_WEB">{constant.web_general}</option>
                    <option value="GEN_VOICE">{constant.voice_general}</option>
                  </select>
                </div>
              </div>

              <div className="yr-body-p yr-mt-20">
                <Message
                  color={
                    this.state.userToasterColor
                      ? this.state.userToasterColor
                      : "teal"
                  }
                  style={{
                    display: this.state.userToaster ? "block" : "none",
                    marginTop: 30,
                  }}
                >
                  {this.state.userToasterMsg}
                </Message>
                <div className="row">
                  {/* <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                        <Link to="#" className="btn yr-link mb-3 mr-4">View live Workspaces</Link>
                    </div> */}
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    {loading && <div className="ui loading form"></div>}
                    <div className="yr-workspace">
                      <ul>
                        <li
                          onClick={() => this.addWorkspace()}
                          className="cursordiv"
                        >
                          <div className="row">
                            <div className="col-md-4 kt-align-center">
                              <img src="assets/media/icons/plus.svg" alt="" />
                            </div>
                            <div className="col-md-8">
                              <h2>Add New Workspace</h2>
                            </div>
                          </div>
                        </li>

                        {this.state.list.map((obj, index) => {
                          {
                            var recordid = update_list.findIndex(
                              (list) => list.auto_id === obj.wid
                            );
                          }
                          // console.log(default_list,obj,"obj");
                          return (
                            <li
                              key={obj.wid}
                              style={{ position: "relative" }}
                              className={`${default_list.indexOf(obj.wid) !== -1 ? "active cursorpointer" : "cursorpointer"} ${this.state.allSquadAssistant.length && this.state.allSquadAssistant.includes(Number(obj.wid)) ? 'squad-assistant' : ''}`}
                            >
                              <Loader
                                style={{
                                  display:
                                    recordid != -1 &&
                                    update_list[recordid].loading
                                      ? "block"
                                      : "none",
                                }}
                              ></Loader>
                              <div className="yr-workspaceBoxInner">
                                <div className="dropdown dropdown-inline yr-del-botbuilder">
                                  <button
                                    type="button"
                                    className="btn btn-icon btn-sm btn-icon-md"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="flaticon-more" />
                                  </button>
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    style={{ paddingRight: "5px" }}
                                  >
                                    <Link
                                      className="dropdown-item"
                                      to={"/botbuilder/" + obj.code}
                                    >
                                      <i className="flaticon-eye" /> View
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      onClick={() =>
                                        this.renameWorkspace(obj.wid)
                                      }
                                    >
                                      <i className="la la-edit" /> Rename
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      onClick={() =>
                                        this.deleteWorkspace(obj.wid)
                                      }
                                    >
                                      <i className="flaticon-delete-1" /> Delete
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      onClick={() =>
                                        this.viewDuplcateScreen(
                                          obj.wid,
                                          obj.bot_type
                                        )
                                      }
                                    >
                                      <i className="la la-clone" /> Duplicate
                                    </Link>
                                  </div>
                                </div>
                                <div
                                  className="yr-branchDetails"
                                  onClick={() => this.openWorkspace(obj.code)}
                                >
                                 {(obj.bot_type!='GEN_WEB' && obj.bot_type!='GEN_VOICE') && <h4 className="title" title={`${obj.name} (${obj.bot_type!='GDF' ? obj.bot_type:'GMB'})`}>
                                    {obj.name} ({obj.bot_type!='GDF' ? obj.bot_type:'GMB'}){" "}
                                  </h4>} 

                                  {(obj.bot_type=='GEN_WEB' || obj.bot_type=='GEN_VOICE' ) && <h4 className="title" title={`${obj.name} (${constant[obj.bot_type]})`}>
                                    {obj.name} ({constant[obj.bot_type]}){" "}
                                  </h4>}

                                 
                                  <ul>
                                    <li>
                                      Updated:<span>{obj.updated_date}</span>
                                    </li>
                                    <li>
                                      Created:<span>{obj.created_date}</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                      {this.state.list.length == 0 && (
                        <div style={{ textAlign: "center", fontWeight: "400" }}>
                          No result found
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.modalstatus}
          footer={this.state.modalfooter}
          onclose={this.modalClose}
          title={this.state.modaltitle}
          body={this.state.modalbody}
          modaltranscript={this.state.modalTranscript}
          alert={this.state.alertMsg}
        />
        <DialogBox
          show={this.state.addshow}
          //toggleModal={this.toggleModal}
          //title="Upgrade to use this feature"
          width="850px"
          height="550px"
          //message={this.state.message}
          toggleModal={this.toggleModal}
        >
          {this.state.addworkspace}
        </DialogBox>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(BotBuilder);
