import React from "react";
import Hamburger from "./Hamburger";
import Logo from "./Logo";
import Notification from "./Notification";
import UserInfo from "./UserInfo";
import { connect } from "react-redux";

import { toggleSubMenuSidebar } from "../../../store/Page";


class TopHeaderMobile extends React.Component {
  loadMenuSidebar = () => {
    let body = document.body;

    body.classList.add("kt-aside--on");
    this.props.toggleSubMenuSidebar({ toggle: true });
  };

  render() {
    const { subHeaderTitle, user } = this.props;
    var sidebar_icon_color = user.customizedDashboard[0].sidebar_icon_color;
    if (sidebar_icon_color == '') {
      sidebar_icon_color = '#FFFFFF';
    }

    return (
      <div
        id="kt_header_mobile"
        className="kt-header-mobile  kt-header-mobile--fixed linear-gradient"
      >
        <div className="kt-header-mobile__logo">
          <a to="/">
            <img alt="Logo" src={user.logoUrl} width="100px" style={{maxHeight:'56px'}}/>
          </a>
        </div>
        <div className="kt-header-mobile__toolbar">
          {/*  <button
          className="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
          id="kt_aside_mobile_toggler" onClick={this.loadMenuSidebar}
        >
          <span />
        </button> */}
          <span>
            <i className="fa fa-th mr-2" aria-hidden="true" onClick={this.loadMenuSidebar} style={{ color: sidebar_icon_color }}></i>
          </span>
          <span>
            <UserInfo user={user} from={"mobile"} />
          </span>

        </div>

      </div>
    );
  }
}

// export default TopHeaderMobile;
export default connect(null, { toggleSubMenuSidebar })(TopHeaderMobile);
