import React from "react";
import { connect } from "react-redux";
import { toggleSubMenuSidebar } from "../../store/Page";
import { Link, withRouter } from "react-router-dom";

class Sidebar extends React.Component {
  constructor(props) {
    // console.log("leftBar",props)
    super(props);
    this.state = {
      subMenu: "",
      menu: props.user.leftBarAccessList,
      sidebar_color: props.user.customizedDashboard[0].sidebar_color,
      sidebar_icon_color: props.user.customizedDashboard[0].sidebar_icon_color
      //[
      //   {
      //     title: "Dashboard",
      //     key: "dashboard",
      //     icon: "yr-navicon-user",
      //   },
      //   {
      //     title: "Conversation",
      //     key: "conversation",
      //     icon: "yr-navicon-t-script",
      //     childElement: [
      //       { title: "Live Chat", component: "/live-chat" },
      //       { title: "Your Chats", component: "/completed-chats" },

      //       { title: "Chat Setup", component: "/chat-setup" },

      //       {
      //         title: "Installation and Integration",
      //         component: "/installation-and-integration",
      //       },
      //     ],
      //   },
      //   {
      //     title: "Review Management",
      //     key: "review",
      //     icon: "yr-navicon-review",
      //     childElement: [
      //       { title: "Your Reviews", component: "/your-reviews" },
      //       { title: "Team Activity", component: "/team-activity" },
      //       { title: "Request A Review", component: "/request-a-review" },

      //       { title: "Settings", component: "/settings/team-listing" },
      //     ],
      //   },
      //   {
      //     title: "Customers and Analytics",
      //     key: "analytics",
      //     icon: "yr-navicon-codeicon",
      //   },
      //   {
      //     title: "Automated Marketing",
      //     key: "marketing",
      //     icon: "yr-navicon-gareicon",
      //     childElement: [
      //       { title: "Email" },
      //       { title: "Outbound SMS" },
      //       { title: "Your Call List" },
      //       { title: "Configuration Setting" },
      //     ],
      //   },
      //   {
      //     title: "Skills and Workspaces",
      //     key: "workspaces",
      //     icon: "yr-navicon-bot-builder",
      //     childElement: [
      //       { title: "Legacy conversations", component: "/automation" },
      //       { title: "Workspaces", component: "/botbuilder" },
      //       { title: "Intent Engine" },
      //     ],
      //   },
      //   {
      //     title: "Settings",
      //     key: "settings",
      //     icon: "yr-navicon-gareicon",
      //     childElement: [
      //       { title: "Profile", component: "/settings?tab=profile" },
      //       { title: "Manage User", component: "/settings?tab=manageuser" },
      //       { title: "Sensitive Data Activity Log" },

      //       { title: "Support Ticket", component: "/settings?tab=support" },
      //     ],
      //   },
      // ],
    };
  }
  componentWillUnmount() {
    this.unlisten();
  }

  toggleSelectedMenu = (location) => {
    // console.log('selectedMenu 1',location);
    const selectedMenu = this.state.menu.filter((menu, index) => {
      if (menu.component && menu.component.includes()) {
        return menu;
      } else if (menu.childElement && menu.childElement.length > 0) {
        let foundMenu = false;
        menu.childElement.forEach((subMenu) => {
          if (
            subMenu.component &&
            subMenu.component.includes(location.pathname)
          ) {
            foundMenu = true;
          }
        });

        if (foundMenu) return menu;
      }
    });
    // console.log('selectedMenu',selectedMenu);
    if (selectedMenu && selectedMenu.length > 0) {
      this.setState({ subMenu: selectedMenu[0].key });
    }
  };

  componentDidMount() {
    const { location } = this.props;
    this.unlisten = this.props.history.listen((location, action) => {
      document.querySelector("#kt_aside_close_btn").click();
    });
    if (location.pathname !== "") {
      this.toggleSelectedMenu(location);
    }
  }

  toggleMenu = () => {
    let body = document.body;

    body.classList.remove("kt-aside--on");

    this.props.toggleSubMenuSidebar({ toggle: false });
  };
  toggleSubMenu = (subMenu, component) => {
    if (this.state.subMenu === subMenu) {
      subMenu = "";
    }
    if (component) {
      this.props.history.push(component);
    }
    this.setState({ subMenu });
  };

  displayChildElement = (item) => {
    // console.log('item child', item);

    // check if childElement has deep-call-analytics and props.user.is_vocera is No then remove it
    if (item.some(child => child.component === "/deep-call-analytics" && this.props.user.is_vocera === "No")) {
      item = item.filter(child => child.component !== "/deep-call-analytics");
    }

    // console.log('item child', item);
    

    return item.map((listItem) => {
      let linkTarget
      if (typeof listItem.component == "undefined") {
        linkTarget = listItem.component ? listItem.component : "#";
      } else if (listItem.component.includes("setting")) {
        linkTarget = listItem.component ? listItem.component : "#";
      } else {
        linkTarget = {
          pathname: listItem.component ? listItem.component : "#",
          key: Date.now(), // we could use Math.random, but that's not guaranteed unique.
          state: {
            applied: true
          }
        };
      }

      return (
        <li className="kt-menu__item " aria-haspopup="true">
          <Link
            className="kt-menu__link"
            to={linkTarget}
            onMouseOver={this.aClass}
            onMouseLeave={this.rClass}

          >
            <i className="kt-menu__link-bullet kt-menu__link-bullet--dot" >
              <span
                style={{ backgroundColor: this.state.sidebar_icon_color }}
                onMouseOver={this.aClass}
                onMouseLeave={this.rClass}></span>
            </i>
            <span className="kt-menu__link-text" style={{ color: this.state.sidebar_icon_color }}>{listItem.title}</span>
          </Link>
        </li>
      );
    });
  };

  displayMenu = () => {
    //alert(this.state.sidebar_icon_color);

    return this.state.menu.map((item, index) => {
      // console.log('item', item);
      return (
        // Added by Rohit
        <li className="kt-menu__item  kt-menu__item-submenu " key={index} >
          <a
            className="kt-menu__link kt-menu__toggle "
            onClick={() => this.toggleSubMenu(item.key, item.component)}
            style={{
              backgroundColor: this.state.subMenu === item.key ? this.state.sidebar_icon_color + '4d' : "",

            }}
            onMouseOver={this.aClass}
            onMouseLeave={this.rClass}
          >
            <i className={`kt-menu__link-icon ${item.icon}`} style={{ color: this.state.sidebar_icon_color }}></i>
            <span className="kt-menu__link-text" style={{ color: this.state.sidebar_icon_color }}>{item.title}</span>
            {item.childElement && (
              <i className="kt-menu__ver-arrow chevron right icon" style={{ color: this.state.sidebar_icon_color }}></i>
            )}
          </a>

          {item.childElement && (
            <div
              className="kt-menu__submenu "
              key={index}
              style={{
                display: this.state.subMenu === item.key ? "block" : "none",
              }}
            >
              <span className="kt-menu__arrow"></span>
              <ul className="kt-menu__subnav">
                {this.displayChildElement(item.childElement, item.key)}
              </ul>
            </div>
          )}
        </li>
      );
    });
  };

  changeBG = () => {
    var e = this.state.sidebar_color;
    //element.style.color = 'blue';
    document.getElementById("kt_aside_close_btn").style.backgroundColor = e;
    // e.target.style.backgroundColor = this.state.sidebar_icon_color
    //this.setState({ this.state.sidebar_icon_color });
  }

  changeTxt(e) {
    // e.target.style.color = this.state.sidebar_icon
  }

  render() {
    const className = `kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop ${this.props.toggle ? "kt-aside--on" : ""
      }`;
    return (
      <React.Fragment>
        <button
          className="kt-aside-close b-close"
          id="kt_aside_close_btn"
          onClick={this.toggleMenu}
          style={{ backgroundColor: this.state.sidebar_icon_color }}
        >
          <i className="la la-close"
            style={{ color: this.state.sidebar_color }}
          />
        </button>
        <div className={className} id="kt_aside">
          <div
            className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
            id="kt_aside_menu_wrapper"
            style={{ backgroundColor: this.state.sidebar_color }}
          >
            <div
              id="kt_aside_menu"
              className="kt-aside-menu "
              data-ktmenu-vertical={1}
              data-ktmenu-scroll={1}
            >
              <ul className="kt-menu__nav ">{this.displayMenu()}</ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    toggle: state.page.toggleSubMenuSidebar,
  };
};
export default connect(mapStateToProps, { toggleSubMenuSidebar })(
  withRouter(Sidebar)
);
