import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function createMarkup(html){
  return {__html: html};
}

export const DatatableRow = props => {
  let parseData = props?.list?.custom_capture ? props?.list?.custom_capture : {};
 return(<Table.Row >
    <Table.Cell>
    
    <label className="kt-checkbox kt-checkbox--single"><input type="checkbox" checked={props.list.checked ? true : false}  auto_id={props.list.auto_id} onChange={()=>props.channgeEvent(props.list.auto_id)}></input>&nbsp;<span></span></label>
    
    </Table.Cell>
    <Table.Cell>{props.list.chat_process ? (props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span ><span className={
      props.list.live_chat_start=='1' ?
      'kt-badge  kt-badge--warning kt-badge--inline kt-badge--pill' : 
      props.list.chat_process=='Completed' ? 
      'kt-badge  kt-badge--success kt-badge--inline kt-badge--pill':
      'kt-badge  kt-badge--error kt-badge--inline kt-badge--pill'}>
    {props.list.live_chat_start=='1' ? 'Live' : props.list.chat_process}</span></span> :'' }</Table.Cell>
    <Table.Cell>{props.list.chat_from ? (props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>: <span ><span className="kt-badge  kt-badge--web kt-badge--inline kt-badge--pill">{props.list.chat_from}</span></span> :'' }</Table.Cell>
    <Table.Cell >{props.list.chat_from ? (props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>: <span ><span style={{overflow: "hidden" ,display:"block",maxWidth: "70px",textOverflow: "ellipsis",lineHeight: "3px"}} className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill" title={props.list.referrer_page} >{props.list.referrer_page}</span></span> :'' }</Table.Cell>
    <Table.Cell >{props.list.traffic_medium ? (props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>: <span ><span style={{overflow: "hidden" ,display:"block",maxWidth: "70px",textOverflow: "ellipsis",lineHeight: "3px"}} className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill" title={props.list.traffic_medium} >{props.list.traffic_medium}</span></span> :'' }</Table.Cell>
    <Table.Cell  style={{whiteSpace: "nowrap"}}>{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:props.list.date_added}</Table.Cell>
    <Table.Cell style={{whiteSpace: "nowrap",textOverflow: "ellipsis",overflow: "hidden",maxWidth:"170px"}}>{(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:props.list.job_description} </Table.Cell>
    <Table.Cell style={{whiteSpace: "nowrap"}}>{(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);"></a></span>:<a className="cursorpointer des-link" onClick={()=>props.viewDetails(props.list.auto_id,props.list.isgpt,)}>View Details</a>}</Table.Cell>

    <Table.Cell style={{width: "150px"}}>{props.list.anonymous_user_id?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<div dangerouslySetInnerHTML={{__html: props.list.anonymous_user_id }}></div>:""}</Table.Cell>
   
    
    
    <Table.Cell id={"trans_chatstatus_" + props.list.auto_id} style={{width: "150px"}}>{props.list.chat_status?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:props.list.chat_status:""}</Table.Cell>

    <Table.Cell style={{width: "150px"}}>{props.list.user_name?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<div dangerouslySetInnerHTML={{__html: props.list.user_name }}></div>:""}</Table.Cell>
   
    
    <Table.Cell>{props.list.zip_code?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<div dangerouslySetInnerHTML={{__html: props.list.zip_code}}></div>:""}</Table.Cell>

    <Table.Cell>{props.list.phone_number?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<div dangerouslySetInnerHTML={{__html: props.list.phone_number}}></div>:""}</Table.Cell>

    <Table.Cell>{props.list.chat_email?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<div dangerouslySetInnerHTML={{__html: props.list.chat_email}}></div>:""}</Table.Cell>
	
    {/* <Table.Cell>{props.list.lender?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:props.list.lender:""}</Table.Cell> */}
    
    <Table.Cell style={{whiteSpace: "nowrap"}}>{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span dangerouslySetInnerHTML={createMarkup(props.list.host)}  ></span>}</Table.Cell>
    <Table.Cell >{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span dangerouslySetInnerHTML={createMarkup(props.list.utm_source)}  ></span>}</Table.Cell>
    <Table.Cell >{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span dangerouslySetInnerHTML={createMarkup(props.list.utm_medium)}  ></span>}</Table.Cell>
    <Table.Cell >{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span dangerouslySetInnerHTML={createMarkup(props.list.utm_campaign)}  ></span>}</Table.Cell>
    <Table.Cell >{(props.list.hippaOn =="block1")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:<span dangerouslySetInnerHTML={createMarkup(props.list.utm_content)}  ></span>}</Table.Cell>
  
    <Table.Cell>{props.list.location_name?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:props.list.location_name:""}</Table.Cell>
    <Table.Cell>{props.list.additionl_captured ? (props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>: <a className="cursorpointer des-link" onClick={()=>props.viewAdditionalCaptured(props.list.auto_id,'additional_captured')}> View </a> : ''}     </Table.Cell>
    <Table.Cell>{props.list.team_notes ? (props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>: <a className="cursorpointer des-link" onClick={()=>props.viewNotes(props.list.auto_id)}>View Notes</a> :''}</Table.Cell>
    <Table.Cell>{props.list.ip_address?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:props.list.ip_address:""}</Table.Cell>
    <Table.Cell>{props.list.ga_id?(props.list.hippaOn =="block")?<span><a onClick={()=>props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span>:props.list.ga_id:""}</Table.Cell>
    <Table.Cell className="cWidth" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" }}>
			<div style={{ display: "contents", width: 50 }}>{(props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);"></a></span> : <a className="cursorpointer des-link" onClick={() => props.viewPaerseData(parseData,props.list.auto_id,props.list.isgpt)}>View Parsed Data</a>}
			</div>
		</Table.Cell>
    <Table.Cell className="cWidth" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" }}>
          <div style={{ display: "contents", width: 50 }}>{(props.list.hippaOn == "block") ? <span><a onClick={() => props.openHipaaRow(props.list.auto_id)} href="javascript:void(0);">Hidden</a></span> :<span style={{display: 'flex', flexDirection: 'column', rowGap: 8}}><a className="cursorpointer kt-badge  kt-badge--info kt-badge--inline kt-badge--pill" style={{color: 'white', fontWeight: 500}} onClick={() => props.notificationDetails(props.list.auto_id)}>View Details </a> </span>}
          </div>
    
          
        </Table.Cell>
    
  </Table.Row>
);
}

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
