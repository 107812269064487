import React, { useEffect, useState, createContext } from 'react';
import dataconnection from '../../../api/DataConnection/dataconnection'
import DatalayerandTooling from '../../../api/DatalayerandTooling'

// Create context object using createContext
export const DataContext = createContext();

// Create context provider
export const DataProvider = ({ children }) => {
    const [data, setData] = useState(5);
    const [connections, setConnections] = useState(undefined);
    const [turboTools, setTurboTools] = useState([]);
    const [toolapiendpoint,setToolapiendpoint] = useState(undefined);

    const loaddata = () => {
        dataconnection.getConnectionList({}).then( res => {
            setConnections(res)
        })
    }

    const loadTool = (workspace_id) => {
        DatalayerandTooling.getNewturboTool({workspace_id:workspace_id}).then((res) =>{
            setTurboTools(res.data)
        })
    }

    const loadToolApiendpoint = () => {
        dataconnection.getDataApiConnectionList({}).then((res) =>{
            setToolapiendpoint(res.data)
        })
    }

    useEffect(() => {
        loaddata();
        loadToolApiendpoint();
    }, []);

    const updateData = (updatedData) => {
        setData(updatedData);
    };
    
    const updateConeection = (data) => {
        setConnections(data)
    }

    const updateTurboToolsWithapi = (updatedata = turboTools) => {
        setTurboTools([...updatedata])
    }


    return (
        <DataContext.Provider value={{ 
            data, 
            updateData,
            connections, 
            updateConeection,
            updateTurboToolsWithapi,
            turboTools,
            loadTool,
            loaddata,
            toolapiendpoint,
            loadToolApiendpoint
            }}>
            {children}
        </DataContext.Provider>
    );
};
