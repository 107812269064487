import React from "react";
import CustomErrorField from "../../../../Form/CustomErrorField";
import './parseData.css';
import api from "../../../../../api";
import { Button, Checkbox,Dropdown,Table } from 'semantic-ui-react'
import { fas } from "@fortawesome/free-solid-svg-icons";
import {countrieslist,statelist,address_terms} from "./CountriesList";
import Modal from "../../../ChatSetup/components/Modal";
import ConfirmDialog from "../../../../Modal/ConfirmDialog";

class DataParseRule extends React.Component {
    countries=countrieslist;
    states = statelist;
   address_fields=address_terms;
   state_terms={usa:'State',canada:'Province',aus:'State'} ;
   zip_terms={usa:'Zip Code',canada:'Postal Code',aus:'Postcode'} ;  
    
    constructor(props){
        super(props);
        this.state={
            listOfData : [{rule:"",edit:true,required:false,date_required:false,is_address:false,map:false}],
            showNotification:false,
            alertData:{type:"sucess",message:"Data Parse Rule Updated Successfully."},
            is_required:false,
            date_required:false,
             timezone:"",
            timezones:"",
            useAddress:false,
            state:'',
            country:"",
            auto_sent:false,
            modalOpen:false,
            modalBody:'',
            modalFooter:'',
            modalTitle:"",
            curField:'',
            curIndex:-1,
        }
       
        
    }
    onload = () => {
        api.botbuilder.getParseDaataConfigration({workspace_id:atob(this.props.workspace_id)}).then( res => {
            if(res.data && res.data.length > 0){
                let reslist=res.data;
                let  resplist=reslist.map(resp => {
                    let s=  typeof resp.required=='undefined'? {...resp,required:false,date_required:false,is_address:false} : resp;
                    return s;
                  });
                this.setState({
                    listOfData : resplist,
                    timezones:res.timezones,
                    is_required : res.is_required,
                    datetime_required : res.date_required,
                    timezone:res.timezone,
                    useAddress:res.use_address,
                    auto_sent:res.auto_sent,
                    state:res.state,
                    country:res.country,
                    
                })
            }else{
                this.setState({
                    listOfData : [ {rule:"",edit:true,required:false,name:'',date_required:false,is_address:false}],
                    timezones:res.timezones,
                    country:'',
                    state:""
                })
            }
        }
        )
        .catch((err) => {
            console.log(err)
        })
    }
    timeZoneOption = () => {
        let list = Object.keys(this.state.timezones).map((key, index) => {
            return {
                key: index,
                text: this.state.timezones[key],
                value: key
            }
        });
        return list;
    }
    handleTimeZoneSelect = (e, { value }) => {
        const timeZoneName = value;
         if(timeZoneName.length>0) {
            const timeZoneValue = this.state.timezones[timeZoneName];        
            let currentTime = new Date().toLocaleString("en-US", { timeZone: timeZoneName });
            this.setState({timezone:timeZoneName}) ;
         } else{
          this.setState({timezone:""}) ;
         }  
        
       
    }

    handleCountries = (e, { value }) => {
         let prevstat=this.state.country;
         let fields=this.state.listOfData;
         fields=fields.filter((it)=>{
            return  it.is_address!=true
        });
         if(value.length>0) {
            fields=fields.filter((f)=>{
                console.log(f.rule,this.address_fields[value].includes(f.rule.trim()));
                return !this.address_fields[value].includes(f.rule.trim());
           });
                for(let i=0;i<this.address_fields[value].length;i++){
                    let rullname=this.address_fields[value][i];
                    fields.push({rule:rullname,required:true,name:rullname.trim().toLowerCase(),date_required:false,is_address:true,edit:false});
                }
         } 
         this.setState({country:value,listOfData:fields});

    }
    handlestates=(e, { value }) => {
        if(value.length>0) {
            this.setState({state:value}) ;
         } else{
          this.setState({state:""}) ;
         }  
    }

    componentDidMount(){
        this.onload()
    }
    markrequired=()=>{
        let req=!this.state.is_required;
        this.setState({is_required:req});
    }
    markAutoSent=()=>{
        let req=!this.state.auto_sent;
        this.setState({auto_sent:req});
    }
    
    daterequired=()=>{
        let req=!this.state.datetime_required;
        this.setState({datetime_required:req});
    }
    useAddress=()=>{
        let req=!this.state.useAddress;
       let fields= this.state.listOfData;
       let country=this.state.country;
       country=['usa','canada','aus'].includes(country) ? country:'';
        fields=fields.filter((it)=>{
            return  it.is_address!=true
        });
        if(req && country.trim()!=='' && ['usa','canada','aus'].includes(country)){
            fields=fields.filter((f)=>{
                return !this.address_fields[country].includes(f.rule.trim());
           });
            for(let i=0;i<this.address_fields[country].length;i++){
                let rullname=this.address_fields[country][i];
                fields.push({rule:rullname,name:rullname.trim().toLowerCase(),required:true,date_required:false,is_address:true,edit:false});
            }
        }
       
        this.setState({useAddress:req,listOfData:fields});
    }
    makerequired=(val,i)=>{
        let fields=this.state.listOfData;
        fields[i].required=!fields[i].required
        this.setState({listOfData:fields}); 
        
    }
    
    makedtrequired=(val,i)=>{
        let fields=this.state.listOfData;
        fields[i].date_required=!fields[i].date_required;
        this.setState({listOfData:fields}); 
        
    }

    markaddress=(val,i)=>{
        let fields=this.state.listOfData;
        fields[i].is_address=!fields[i].is_address
        
       
        this.setState({listOfData:fields}); 
    }
    renderParseConfigration = () => {
        console.log(this.state.listOfData,'this.state.listOfData')
            return (
            this.state.listOfData.map((rule,index) => { 
                let rules=typeof rule.rule !='undefined' ? rule.rule.split("("):[];
                let keypart=typeof rules[0] !='undefined' ? rules[0].trim():'';
                let commpart=typeof rules[1]!='undefined' ? rules[1]:'';
                let ncommpart=commpart.replaceAll(",","").replaceAll(")","").trim();
                console.log(ncommpart,"space",commpart)
                commpart=commpart.length >0 && commpart.includes(")") ? commpart.slice(0,-1):'';
                return(<li className="row mb-3">
                 {this.state.is_required &&  <div className="col-1">
                  <Checkbox
                                onChange={(e) => this.makerequired(rule.rule,index)}
                                checked={rule.required}
                               
                            />
                            
                 </div>}
                 {this.state.datetime_required &&  <div className="col-1">
                  <Checkbox
                                onChange={(e) => this.makedtrequired(rule.rule,index)}
                                checked={rule.date_required}
                                 className="datecheckbox"
                                 disabled={rule.is_address}
                            />
                            
                 </div>}

                 {this.state.useAddress &&  <div className="col-1">
                  <Checkbox
                                onChange={(e) => this.markaddress(rule.rule,index)}
                                checked={rule.is_address}
                                disabled={true}
                                 className="isaddress"
                            />
                            
                 </div>}
            <div className={this.state.is_required ? 'col-7':'col-10'}>
                            <input type="text" className="form-control" placeholder="Parse Rule" onChange={(event => this.handleParseRuleChange(index,event.target.value) )} value={rule.rule} defaultValue={rule.rule} disabled={!rule.edit}/> 
            </div>
           {/* { !rule.edit && <div className="col-1"><button type="button" className="icon-btn-spl" onClick={this.handleEdit(index)}><i class="la la-edit blue" title="Edit"></i></button></div>} */}
            {this.state.listOfData.length > 1  && 
            <div className="col-1">{!rule.is_address && <button disabled={rule.is_address} style={{marginTop: '-8px' }} type="button" className="icon-btn-spl" onClick={this.removeParseRule(index,rule)}><i class="la la-trash red" title="Delete"></i></button>}
            </div>}
        
            <div className="col-1">{!rule.is_address &&  ncommpart.length>0 && commpart.trim().length>0 && rule.rule.includes(")") && <button disabled={rule.is_address} style={{marginTop: '-8px',marginLeft:'10px' }} type="button" className="icon-cog-spl" onClick={(e)=>this.configfieldVals(index,rule,commpart,keypart)}><i class="fa fa-cog" title="Configure values"></i></button>}
            </div>
        </li>); 
        }
        ))
    }

    closeModal=()=>{
        // let cur_index=this.state.curIndex;
        // let lists=this.state.listOfData;
        // let clist=lists[cur_index];

        this.setState({modalOpen:false,curIndex:-1,curField:'',modalBody:'',modalFooter:''});
    }
   
    getParseValues=(val,prev)=>{
        let map_values=this.state.map_values;
        let keydata=typeof val.rule!='undefined' ? val.rule.split("("): val.split("(");
        let prevdata=typeof prev!='undefined' ? prev.split("("): '';
        let values=[];
        let exitstvalue=typeof map_values[keydata[0].trim()]!='undefined' ? map_values[keydata[0].trim()]:'';
       
        if(exitstvalue && exitstvalue.length>0){
            values=exitstvalue;
        }
        let values1=keydata[1].length ? keydata[1].slice(0,-1):"";
        values1=values1.length>0 ? values1.split(","):[];

        console.log(values,values1,'map w');
        values=values1.map(value=>{
            if(prevdata.length>0){
                let exist=typeof map_values[prevdata[0]]!='undefined' ? map_values[prevdata[0]]:'';
                
            }
            return {key:value.trim().toLowerCase(),name:value,value:""};
        });
        
        return values;
    }
    configBody=(values,key,i)=>{
        console.log("body",values);
        if(typeof values['map']!='undefined' &&  values['map'].length){
         return(<Table id="additional_captured"><Table.Body>
            {values['map'].map((vl)=>{
                return(<Table.Row key={vl.key}><Table.Cell>{vl.name}</Table.Cell><Table.Cell><input type="text" class="form-control" value={vl.value} name={vl.key} onChange={(e)=>this.updateMapping(e,vl)}  /></Table.Cell></Table.Row>)
            })}
         </Table.Body></Table>);
        }else{
            let valuesar=values.rule.split("(");
            if(typeof valuesar[1]!='undefined'){
                let vls=valuesar[1].slice(0,-1);
                vls=vls.split(",");
                vls=vls.map((itr)=>{
                     return {key:itr.trim().toLowerCase(),name:itr,value:""}
                });
               let lists=this.state.listOfData;
               lists[i]['map']=vls;
               lists[i]['name']=valuesar[0].trim().toLowerCase();
                this.setState({listOfData:lists});
                return(<Table id="additional_captured"><Table.Body>
                    {vls.map((vl)=>{
                        return(<Table.Row key={vl.key}><Table.Cell>{vl.name}</Table.Cell><Table.Cell><input type="text" class="form-control" value="" name={vl.value} onChange={(e)=>this.updateMapping(e,vl)}  /></Table.Cell></Table.Row>)
                    })}
                 </Table.Body></Table>);
            }
            return(<p>No Data</p>);
        }
    }
    updateMapping=(e,vl)=>{
           let index=this.state.curIndex;
           console.log(vl,index,this.state.curField);
           let lists=this.state.listOfData;
           let curlist=lists[index];
           let curmap=typeof curlist['map']!=='undefined' ? curlist['map']:'';
           if(Object.keys(curlist).length>0){
            curmap= curmap.map(it=>{
                console.log(it);
                    if(it.name==vl.name){
                        it['value']=e.target.value;
                    }
                    return it;
                });
                
                curlist['map']=curmap;
                lists[index]={...lists[index],map:curmap};
                console.log(curlist,curmap,lists);
                this.setState({listOfData:lists},()=>{
                   let body= this.configBody(curlist,'key',index);
                   this.setState({modalBody:body});
                });
           }
    }
    saveconfig=(e)=>{
       this.setState({curField:'',modalOpen:false});
       this.saveDataParsetRule();

    }

    configfieldVals=(i,val,valuepart,keypart)=>{
        let editIndexData = [...this.state.listOfData];
        let parseCodeJson = editIndexData[i];
        let labelsForRule = parseCodeJson.rule;
        let keyname=val ? val.rule.split("(")[0]:"";  
        //fetchoing keys from rule
        let vals=labelsForRule.split("(");
        vals[1]=vals[1].includes(")") ? vals[1].trim().slice(0,-1):vals[1].trim();
        vals[1] = vals[1].replace(/,\s*$/, "");
        let vlist=vals[1].split(","); 
        vlist = vlist.filter(item => item.trim().length>0);
        vlist=vlist.filter((value, index, array)=> {
            return array.indexOf(value) === index;
          });

        let nrule=  keyname+"("+vlist.join(",")+")";
        let vlist1 = vlist.map(item => item.trim().toLowerCase())
        let valmap=typeof val.map!='undefined' ? val.map:[];
        let valkeys=valmap.map(vl=>vl.key);
        let dif=vlist.filter(it=>{
            return !valkeys.includes(it.toLowerCase().trim());
        });
        let mapdata = [...valmap];
        dif.forEach(element => {
            mapdata.push({key:element.trim().toLowerCase(),value:'',name:element})
        });
        valmap = mapdata;
        valmap = valmap.filter( item => {
            return vlist1.includes(item.key)
        });
        val['map']=valmap;
        val['name']=keyname;
        val['rule']=nrule;
        //console.log(valkeys,vlist,'called',dif,val);
        editIndexData[i] = val;


        let body=this.configBody(val,keypart,i);
        let footer=(<div className="modal-footer">
            <button
              type="button"
              onClick={(e) => this.saveconfig(e)}
              className="btn  linear-gradient yr-submitbtn btn-sm"
            >
              <span>Save</span>
            </button>
            <button
                            type="reset"
                            class="btn yr-cancelbtn linear-gradient-cancel mg-r"
                            onClick={() => this.closeModal()}
                            >
                                <span> Close </span>
                            </button>
          </div>);
       //  editIndexData = [...new Set(editIndexData.map(item => item.rule.trim()))];
        this.setState({modalOpen:true,modalBody:body,modalFooter:footer,modalTitle:`Add values for ${keyname}`,curField:keyname.trim().toLowerCase(),curIndex:i});
    }
    handleEdit = index => () => {
        this.setState({
            listOfData : this.state.listOfData.map((rule,i) => i === index ? {...rule,edit:!rule.edit} : rule)
        })
    }
    handleParseRuleChange = (index,value) => {
        let lists=[...this.state.listOfData];
       
        let curlist={...lists[index]};
        let vlaues=value.split("/(/");
        console.log(vlaues,"=== 1",value);
        let keypart=typeof vlaues[0]!='undefined' ? vlaues[0].trim().toLowerCase():value.trim().toLowerCase();
        curlist={...curlist,rule:value,name:keypart};
        lists[index] = curlist;
        this.setState({listOfData:lists})
    }
    removeParseRule = (index,rule) => () => {
        this.setState({
            listOfData : this.state.listOfData.filter((rule,i) => i !== index),
        })
    }
    addParseRule = () => {
        this.setState({
            listOfData : [...this.state.listOfData,{rule:"",edit:true,required:false,name:'',date_required:false,is_address:false}]  
        });
        document.getElementById("endrow").scrollIntoView();
    }
    importParseRule = () => {
        api.botbuilder.importParseDaataConfigration({workspace_id:this.props.workspace_id}).then( res => {
            if(res.status){
                let reslist=res.data;
                 let  resplist=reslist.map(resp => {
                    return typeof resp.required=='undefined'? {...resp,required:false,name:'',date_required:false,is_address:false} : resp.required;
                  });
                 this.onload();
                 this.setState({
                 listOfData : [...resplist,{rule:"",edit:true,required:false,date_required:false}]
                });
            }
            this.showNotification("import");
           
        }).catch((err) => {
            console.log(err)
        })
        
    }
    saveDataParsetRule = () => {

        if(this.state.is_required==1){
            let fls=this.state.listOfData.filter(item=>item.required==true);
            if(fls.length==0){
                this.setState({
                    alertData:{type : "red",message:"Please mark at least one field as required."},
                    showNotification:true,
                });
                window.scrollTo(0,0);
                setTimeout(() => {
                    this.setState({
                        showNotification:false,
                        alertData:{type : "sucess",message:"Data Parse Rule Updated Successfully."}
                    })
                }, 4000);
                return false;
            }
           
        }
       
            let blank=this.state.listOfData.filter(item=>item.rule.trim().length==0);
            if(blank.length>0){
                this.setState({
                    alertData:{type : "red",message:"Data parse rule cannot be blank."},
                    showNotification:true,
                });
                window.scrollTo(0,0);
                setTimeout(() => {
                    this.setState({
                        showNotification:false,
                        alertData:{type : "sucess",message:"Data Parse Rule Updated Successfully."}
                    })
                }, 4000);
                return false;
            }
           
        
        if(this.state.timezone=="" && this.state.date_required){
            document.getElementById("usetimezone").focus();
                this.setState({
                    alertData:{type : "red",message:"Please select timezone."},
                    showNotification:true,
                });
                window.scrollTo(0,0);
                setTimeout(() => {
                    this.setState({
                        showNotification:false,
                        alertData:{type : "sucess",message:"Data Parse Rule Updated Successfully."}
                    })
                }, 4000);
                return false;
           
        }
        if(this.state.country=="" && this.state.useAddress){
           document.getElementById("countryid").focus();
            this.setState({
                alertData:{type : "red",message:"Please select a country."},
                showNotification:true,
            });
            window.scrollTo(0,0);
            setTimeout(() => {
                this.setState({
                    showNotification:false,
                    alertData:{type : "sucess",message:"Data Parse Rule Updated Successfully."}
                })
            }, 4000);
            return false;
       
    }
        let data = {workspace_id:atob(this.props.workspace_id),data:[...this.state.listOfData],is_required:this.state.is_required,date_required:this.state.datetime_required,timezone:this.state.timezone,use_address:this.state.useAddress,country:this.state.country,state:this.state.state,auto_sent:this.state.auto_sent,parse_data:this.state.map_values}
        let filteredData = data.data.filter( datt => datt.rule.trim() != '')
        if(filteredData.length > 0){
            data = {...data,data:filteredData}
            api.botbuilder.saveParseDaataConfigration({data:data}).then(res => {
                this.showNotification();
                window.scrollTo(0, 0);
                this.onload()

            }).catch((err) => {
                
            })
        }else{
            this.showNotification('alert');
            this.setState({
                alertData:{type : "red",message:""},
                showNotification:true,
            });
        }
        setTimeout(() => {
            this.setState({
                showNotification:false,
                alertData:{type : "sucess",message:"Data Parse Rule Updated Successfully."}
            })
        }, 4000);
    }
    showNotification = (msg = "") => {
        if(msg == "alert"){
            this.setState({
                alertData:{type : "red",message:"Please set at least on data parsing rule."}
            })
        }
        if(msg=='import'){
            this.setState({
                alertData:{type : "sucess",message:"Data imported successfully."}
            })
        }   
        this.setState({
            showNotification:true
        })
        setTimeout(() => {
            this.setState({
                showNotification:false,
                alertData:{type : "sucess",message:"Data Parse Rule Updated Successfully."}
            })
        }, 2000);
    }
   
    render(){
        return (
            <>
            <div className="parsescreen">
                <div className="yr-chat-round-box yr-closebtn">
                    <button
                        type="button"
                        class="close linear-gradient"
                        onClick={() => this.props.popupClose()}
                    >
                        &#10006;
                    </button>
                    <div className="box-title">Add Data Parse Rule 
                        <button type="button" title="Add Data Parse Rule." className="btn btn-sm btn-clean btn-icon" onClick={(e) => this.addParseRule()}>
                            <img src="../../assets/media/icons/plus.svg" alt="" width={15} />
                        </button>
                        {this.props.bot_type=='WEB' && <button
                                    type="button"
                                    title="Import Data Parse Rule From Voice."
                                    className="btn linear-gradient-cancel yr-cancelbtn mg-r"
                                    onClick={(e) => this.importParseRule()}
                                  >
                                    <span> Import from turbo </span>
                                  </button>
                                 
                       }
                       <p style={{marginLeft:this.state.is_required || this.state.datetime_required ? '-9px': '0'}}>
                                <Checkbox
                                label='Mark Fields Required'
                                onChange={(e) => this.markrequired()}
                                checked={this.state.is_required}
                            />
                           
                             
                        </p> 
                        {this.state.is_required && <p style={{marginLeft:"-10px"}}>
                                <Checkbox
                                label='If all required fields are gathered, feed data automatically'
                                onChange={(e) => this.markAutoSent()}
                                checked={this.state.auto_sent} 
                            />
                            </p>}
                        <p style={{marginLeft:this.state.is_required || this.state.datetime_required ? '-9px': '0'}}>
                        <Checkbox
                        label="Date/Time Required"
                        onChange={(e) => this.daterequired()}
                        checked={this.state.datetime_required}
                        className="datecheckox"
                        />
                        <span style={{marginLeft:"10px"}}>
                        {this.state.timezones && this.state.datetime_required &&
                                   
                                   <Dropdown 
                                        placeholder='Select Time Zone'
                                        search
                                        selection
                                        options={this.timeZoneOption()}
                                        value={this.state.timezone} 
                                        name="timezone"
                                        onChange={this.handleTimeZoneSelect}
                                        clearable
                                        id="usetimezone"
                                    />
                                  
                            }
                        </span>
                       
                        
                         
                    </p> 
                    <p style={{marginLeft:this.state.is_required || this.state.datetime_required ? '-9px': '0'}}>
                                <Checkbox
                                label='Use Address Validation'
                                onChange={(e) => this.useAddress()}
                                checked={this.state.useAddress}
                                 className="addresscheckbox"
                            />
                             <span style={{marginLeft:"10px"}}>
                        { this.state.useAddress &&
                                   <Dropdown 
                                        placeholder='Select Country'
                                        search
                                        selection
                                        options={this.countries}
                                        value={this.state.country} 
                                        name="country"
                                        onChange={this.handleCountries}
                                        clearable
                                        id="countryid"
                                    />
                                  
                            }
                        </span>
                        </p> 

                        <p>
                           
                        { this.state.country && this.state.useAddress &&<> <label style={{color:'rgba(0, 0, 0, .87)',fontSize:'1em'}}>{this.state_terms[this.state.country] } (only choose this if you service one {this.state_terms[this.state.country]})</label>
                            <Dropdown 
                                        placeholder={`Select ${this.state_terms[this.state.country]} `}
                                        search
                                        selection
                                        options={this.states[this.state.country] }
                                        value={this.state.state} 
                                        name="state"
                                        onChange={this.handlestates}
                                        clearable
                                    />
                        </>
                                  
                                  
                            }
                        </p>
                    </div>
                    <div className="row">
                        {this.state.showNotification && <div className={`ui message ${this.state.alertData.type === "sucess" ? "green" : "red"}`} >{this.state.alertData?.message}</div>}
                        <ul className="col-12">
                            {this.renderParseConfigration()}
                        </ul>
                    </div>
                    <div className="row" id="endrow">
                        <div className="col-12 d-flex justify-content-end">
                            <button
                            type="reset"
                            class="btn yr-cancelbtn linear-gradient-cancel mg-r"
                            onClick={() => this.props.popupClose()}
                            >
                                <span> Close </span>
                            </button>
                            <button
                            type="submit"
                            className="btn linear-gradient yr-submitbtn"
                            onClick={() => this.saveDataParsetRule()}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                 </div>
                 <Modal
                          show={this.state.modalOpen}
                          footer={this.state.modalFooter}
                          onclose={this.closeModal}
                          title={this.state.modalTitle}
                          body={this.state.modalBody}
                />
            </div>
            </>
        )
    }
}

export default DataParseRule;
