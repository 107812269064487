import React from "react";
import SubNavigation from "./SubNavigation";
import { Link, withRouter } from "react-router-dom";
import ChatSetupSubNavigation from "./ChatSetupSubNavigation";

class LiveChatNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
    };
  }
  toggleNavMenu = () => this.setState({ toggleMenu: !this.state.toggleMenu });
  render() {
    const { location } = this.props;
    console.log("this.props.user", this.props.user, this.props.user.totalAvailableModules);
    return (
      <div className="kt-header__bottom">
        <div className="kt-container" style={{ backgroundColor: "#fff" }}>
          <div
            className="kt-header-menu-wrapper kt-grid__item kt-grid__item--fluid"
            id="kt_header_menu_wrapper"
          >
            <div
              id="kt_header_menu"
              className="kt-header-menu kt-header-menu-mobile "
            >
              <div className="yr-mobpagetitle">Conversations :</div>
              <ul className="kt-menu__nav " style={{ padding: "22px" }}>
              <li
                  style={{
                    display: this.props.user.totalAvailableModules.includes(
                      "chat-dashboard"
                    )
                      ? ""
                      : "none",
                  }}
                  className={`kt-menu__item ${
                    location.pathname == "/chat-dashboard"
                      ? "kt-menu__item--active linear-gradient-a"
                      : ""
                  }  kt-menu__item--submenu kt-menu__item--rel`}
                  aria-haspopup="true"
                >
                  <Link to="/chat-dashboard" className="kt-menu__link">
                    <span className="kt-menu__link-text">
                      <i
                        className="fa fa-line-chart"
                        aria-hidden="true"
                      ></i>
                      Reporting
                    </span>
                  </Link>
                </li>
                <li
                  style={{
                    display: this.props.user.totalAvailableModules.includes(
                      "livechat"
                    )
                      ? ""
                      : "none",
                  }}
                  className="kt-menu__item kt-menu__item--active linear-gradient-a"
                  aria-haspopup="true"
                >
                  <a
                    nohref={"true"}
                    className="kt-menu__link linear-gradient-a-color"
                  >
                    <span className="kt-menu__link-text">
                      {<i class="fa fa-comment-o" aria-hidden="true"></i>}
                      Livechat
                    </span>
                  </a>
                </li>
                <li
                  style={{
                    display: this.props.user.totalAvailableModules.includes(
                      "your_chat"
                    )
                      ? ""
                      : "none",
                  }}
                  className={`kt-menu__item ${
                    location.pathname == "/completed-chats"
                      ? "kt-menu__item--active linear-gradient-a"
                      : "linear-gradient-a-color"
                  }  kt-menu__item--submenu kt-menu__item--rel`}
                  aria-haspopup="true"
                >
                  <a className="kt-menu__link" href="/completed-chats">
                    <span className="kt-menu__link-text">
                      <i
                        className="fa fa-check-circle-o"
                        aria-hidden="true"
                      ></i>
                      Your Interactions
                    </span>
                  </a>
                </li>
                {this.props.user.is_vocera == 'Yes' && 
                  <li
                  style={{
                    display: this.props.user.totalAvailableModules.includes(
                      "deep-call-analytics"
                    )
                      ? ""
                      : "none",
                  }}
                  className={`kt-menu__item ${
                    location.pathname == "/deep-call-analytics"
                      ? "kt-menu__item--active linear-gradient-a"
                      : "linear-gradient-a-color"
                  }  kt-menu__item--submenu kt-menu__item--rel`}
                  aria-haspopup="true"
                >
                  <a className="kt-menu__link" href="/deep-call-analytics">
                    <span className="kt-menu__link-text">
                      <i
                        className="fa fa-bar-chart-o"
                        aria-hidden="true"
                      ></i>
                      Deep Call Analytics
                    </span>
                  </a>
                </li>
                }  
                <li
                  style={{
                    display: this.props.user.totalAvailableModules.includes(
                      "chat_setup"
                    )
                      ? ""
                      : "none",
                  }}
                  className={`kt-menu__item ${
                    location.pathname == "/chat-setup"
                      ? "kt-menu__item--active linear-gradient-a"
                      : "linear-gradient-a-color"
                  }  kt-menu__item--submenu kt-menu__item--rel`}
                  aria-haspopup="true"
                  data-ktmenu-submenu-toggle="click"
                >
                  {location.pathname == "/chat-setup" ? (
                    <a
                      href="#"
                      onClick={(event) =>
                        this.toggleNavMenu(event, "chatsetup")
                      }
                      className="kt-menu__link kt-menu__toggle "
                    >
                      <span className="kt-menu__link-text">
                        <i className="fa fa-cog" aria-hidden="true"></i>
                        Chat Setup
                      </span>
                      <i className="kt-menu__hor-arrow la la-angle-down" />
                    </a>
                  ) : (
                    <Link to="/chat-setup" className="kt-menu__link ">
                      <span className="kt-menu__link-text">
                        <i className="fa fa-cog" aria-hidden="true"></i>
                        Chat Setup
                      </span>
                      <i className="kt-menu__hor-arrow la la-angle-down" />
                    </Link>
                  )}
                  <ChatSetupSubNavigation
                    display={
                      this.state.toggleMenu && this.state.active == "chatsetup"
                    }
                  />
                </li>
                <li
                  style={{
                    display: this.props.user.totalAvailableModules.includes(
                      "workspace"
                    )
                      ? ""
                      : "none",
                  }}
                  className={`kt-menu__item ${
                    location.pathname.includes("/botbuilder") ||
                    location.pathname.includes("/automation")
                      ? "kt-menu__item--active linear-gradient-a"
                      : ""
                  }kt-menu__item--submenu kt-menu__item--rel`}
                  aria-haspopup="true"
                  data-ktmenu-submenu-toggle="click"
                >
                  <Link to="/botbuilder" className="kt-menu__link ">
                    <span className="kt-menu__link-text">
                      <i className="fa fa-building-o" aria-hidden="true"></i>
                      Workspaces
                    </span>
                  </Link>
                </li>
                <li
                  style={{
                    display: this.props.user.totalAvailableModules.includes(
                      "installation"
                    )
                      ? ""
                      : "none",
                  }}
                  className={`kt-menu__item ${
                    location.pathname.includes("installation")
                      ? "kt-menu__item--active linear-gradient-a"
                      : ""
                  } `}
                  aria-haspopup="true"
                >
                  <Link
                    to="/installation"
                    className="kt-menu__link "
                  >
                    <span className="kt-menu__link-text">
                      <i className="fa fa-file-code-o" aria-hidden="true"></i>
                      Installation{" "}
                    </span>
                  </Link>
                </li>
                {/* <li
                  className={`kt-menu__item ${location.pathname == '/settings' ? "kt-menu__item--active" : ""}  kt-menu__item--submenu kt-menu__item--rel`}

                  data-ktmenu-submenu-toggle="click"
                  aria-haspopup="true"
                >
                  {location.pathname == '/settings' ?
                    <a
                      href="#"
                      onClick={(event) => this.toggleNavMenu(event, 'settings')}
                      className="kt-menu__link kt-menu__toggle"
                    >
                      <span className="kt-menu__link-text">
                       <i className="fa fa-cog" aria-hidden="true"></i>
                        Settings
                    </span>
                      <i className="kt-menu__hor-arrow la la-angle-down" />
                    </a>
                    :
                    <Link to="/settings" className="kt-menu__link ">

                      <span className="kt-menu__link-text">
                        <i className="fa fa-cog" aria-hidden="true"></i>
                        Settings
                    </span>
                      <i className="kt-menu__hor-arrow la la-angle-down" />

                    </Link>
                  }

                  <SubNavigation display={this.state.toggleMenu && this.state.active == 'settings'} />
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(LiveChatNotification);

// export default LiveChatNotification;
