import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Suspense } from 'react';
import VoceraOverviewEmbed from './VoceraEmbed/VoceraOverviewEmbed';
import VoceraCallEmbed from './VoceraEmbed/VoceraCallEmbed';
import VoceraResultEmbed from './VoceraEmbed/VoceraResultEmbed';
import api from '../../../api';

const VoceraCallAnalysis = () => {

    // get user slice
    const user = useSelector((state) => state.user);
    const [activeTab, setActiveTab] = useState('overview');
    const [accessToken, setAccessToken] = useState('');
    const [accessTokenError, setAccessTokenError] = useState('');
    const [loading, setLoading] = useState(true);
    const [agentId, setAgentId] = useState('');
    useEffect(() => {
        // get access token
        getAccessToken();
    }, []);

    const getAccessToken = async () => {
        setLoading(true);
        api.chatsetup.getVoceraAccessToken().then((res) => {
            setLoading(false);
            if (res.success) {
                setAccessToken(res.data.access_token);
                setAgentId(res.data.agent_id);
            } else {
                // display error message using div
                setAccessTokenError(res.message);
            }
        }).catch((err) => {
            setLoading(false);
            setAccessTokenError("Something went wrong. Please refresh the page and try again.");
        });
    }

    return (
        <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <div className="kt-content kt-grid__item kt-grid__item--fluid">
                    <div className="yr-body-p botbuilderdetails">
                        <div class="kt-portlet kt-portlet--tabs yr-mt-20 shadow-none">
                        {loading &&
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <div className="ui active centered inline loader"></div>
                                        </div>
                                    }
                            <div className="kt-portlet__head">
                                <div className="kt-portlet__head-toolbar">                                    
                                    {accessTokenError && <div className="alert alert-danger">{accessTokenError}</div>}
                                    {accessToken && agentId &&
                                        <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x">
                                            <li className="nav-item">
                                                <a className={`nav-link ${activeTab === 'overview' ? 'active' : ''}`} data-toggle="tab"
                                                    role="tab"
                                                    onClick={() => setActiveTab('overview')}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Overview
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link ${activeTab === 'calls' ? 'active' : ''}`} data-toggle="tab"
                                                    role="tab"
                                                    onClick={() => setActiveTab('calls')}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Calls
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link ${activeTab === 'results' ? 'active' : ''}`} data-toggle="tab"
                                                    role="tab"
                                                    onClick={() => setActiveTab('results')}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Results
                                                </a>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                        {accessToken && agentId &&
                            <div className="kt-portlet__body kt-portlet__body--fit">
                                <div className="tab-content">
                                    <Suspense fallback={<div>Loading...</div>}>
                                        {activeTab === 'overview' && <VoceraOverviewEmbed accessToken={accessToken} agentId={agentId} />}
                                        {activeTab === 'calls' && <VoceraCallEmbed accessToken={accessToken} agentId={agentId} />}
                                        {activeTab === 'results' && <VoceraResultEmbed accessToken={accessToken} agentId={agentId} />}
                                    </Suspense>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}



export default VoceraCallAnalysis