import React from "react";

import { Form } from "semantic-ui-react";
import "./create-ticket.css";
import { connect } from "react-redux";
import { toggleNavigation, setPageTitle, setMessage } from "../../store/Page";
import CustomInput from "../Form/CustomInput";
import DropDown from "../Form/DropDown";
import CustomTextArea from "../Form/CustomTextArea";
import CustomImageUploadField from "../Form/CustomImageUploadField";
import Validator from "validator";

import config from "../../config";
import util from "../../util";
import MessageDialog from "../Modal/DialogBox";

class CreateTicket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileModal: false,
      fileModalMessage: "",
      helpOptions: [
        { key: "", value: "Select" },
        { key: "technical_support", value: "Technical Support" },
        { key: "general_question", value: "General Question" },
        { key: "technical_suggestion", value: "Technology Suggestion" },
      ],
      help: "",
      fullName: "",
      company: "",
      email: "",
      summary: "",
      description: "",
      errors: null,
      files: [],
      loading: false,
      showCCModal: false,
      ccEmailInput: "",
      ccEmails: [],
      ccEmailError: "",

      errorMessage: null,
    };
    this.messageRef = React.createRef();
  }

  componentDidMount = () => {
    if (this.props.user && !this.state.fullName) {
      this.setFieldValues(this.props.user);
    }
    this.props.setPageTitle({
      title: "Create Ticket",
      subHeaderTitle: "Settings",
      navigationName: "support",
    });
    this.props.toggleNavigation({ toggle: true });
  };

  closeModal = () => {
    this.setState({ fileModal: false, fileModalMessage: "" });
  };
  addFiles = (file) => {
    const fsize = file.size;
    const fileSize = Math.round(fsize / 1024);
    // The size of the file.

    if (fileSize >= 4096) {
      this.setState({
        fileModal: true,
        fileModalMessage:
          file.name +
          " image size is too large to upload use image size below 4MB.",
      });
    } else {
      let files = this.state.files;
      files.push(file);
      this.setState({ files });
    }
  };
  getLabelInfo = (text, labelRequired = true) => {
    return {
      label: true,
      text,
      labelRequired: labelRequired,
      className: "form-control-label",
    };
  };

  renderOption = () => {
    return this.state.helpOptions.map((option, index) => {
      return (
        <option key={index} value={option.key}>
          {option.value}
        </option>
      );
    });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (this.state.errors && this.state.errors.hasOwnProperty(e.target.name)) {
      this.removeElementFromError(e.target.name);
    }
  };

  removeElementFromError = (keyName) => {
    let { errors } = this.state;
    if (Object.keys(errors).length > 0) {
      delete errors[keyName];
      this.setState({ errors });
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const errors = this.validateForm(this.state);

    this.setState({ errors });

    if (Object.keys(errors).length > 0) {
      let elementId;
      if (errors.hasOwnProperty("help")) {
        elementId = "#help";
      } else if (errors.hasOwnProperty("fullName")) {
        elementId = "#fullName";
      } else if (errors.hasOwnProperty("email")) {
        elementId = "#email";
      } else if (errors.hasOwnProperty("summary")) {
        elementId = "#summary";
      } else if (errors.hasOwnProperty("description")) {
        elementId = "#description";
      }
      if (elementId) document.querySelector(elementId).focus();

      return false;
    }

    try {
      // document.querySelector("#company").focus();
      const formsData = new FormData(
        document.querySelector("#create_ticket_form")
      );
      formsData.delete("images[]");
      if (this.state.files.length > 0) {
        for (let i = 0; i < this.state.files.length; i++) {
          formsData.append("images[]", this.state.files[i]);
        }
      }
      if (this.state.ccEmails.length > 0) {
        formsData.append("ccEmails", JSON.stringify(this.state.ccEmails));
      }
      this.saveRecord(formsData, this);
      this.setState({ loading: true });
    } catch (ex) {
      console.log(ex);
    }
  };

  clearFields() {
    this.setState({ summary: "", description: "", files: [] });
  }
  removeEmail = (emailToRemove) => {
    this.setState((prevState) => ({
      ccEmails: prevState.ccEmails.filter((email) => email !== emailToRemove),
    }));
  };

  saveRecord = (data, obj) => {
    const tokenInfo = util.getAuthTokens();
    var xhr = new XMLHttpRequest();

    xhr.open("POST", config.apiBaseURL + "/settings/support/save", true);

    xhr.setRequestHeader(config.access_token_name, tokenInfo.access_token);
    xhr.setRequestHeader(config.refresh_token_name, tokenInfo.refresh_token);

    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        obj.setState({ loading: false });
        obj.clearFields();
        //console.log(xhr.response);
        if (xhr.response) {
          const resp = JSON.parse(xhr.response);
          obj.props.setMessage({ message: resp.message });
          obj.redirectUser();
        }
      }
    };

    xhr.send(data);
  };

  validateForm = ({ help, fullName, company, email, summary, description }) => {
    const errors = {};

    if (!help) errors.help = "This field is required.";

    if (!fullName) errors.fullName = "This field is required.";

    if (!company) errors.company = "This field is required.";

    if (!email) errors.email = "This field is required.";
    else if (!Validator.isEmail(email)) errors.email = "Invalid email id.";

    if (!summary) errors.summary = "This field is required.";

    if (!description) errors.description = "This field is required.";

    return errors;
  };

  redirectTo = (path) => this.props.history.push(path);
  setFieldValues(user) {
    if (user) {
      this.setState({
        fullName: user.name,
        email: user.email,
        company: user.company,
      });
    }
  }
  redirectUser = () => this.props.history.push("/settings?tab=support");

  render() {
    const { errors, loading } = this.state;
    return (
      <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
        <div className="yr-body-p">
          <MessageDialog
            show={this.state.fileModal}
            title="support"
            message={this.state.fileModalMessage}
            toggleModal={this.closeModal}
          />

          <div className="row">
            <div
              className="col-lg-8 col-md-8 col-sm-12"
              style={{ margin: "auto" }}
            >
              <div className="yr-createTicket">
                <Form
                  className="kt-form kt-form--label-right"
                  onSubmit={this.handleSubmit}
                  id="create_ticket_form"
                  name="create-ticket"
                  encType="multipart/form-data"
                  method="post"
                >
                  <div className="kt-portlet__body">
                    {loading && <div class="ui loading form"></div>}

                    <div className="form-group row">
                      <div className="col-lg-12 col-md-12 col-sm-12 form-group-sub ">
                        <DropDown
                          labelInfo={this.getLabelInfo(
                            "What can we help you with?"
                          )}
                          className={
                            errors && errors.help
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="help"
                          onChange={this.handleInput}
                          value={this.state.help}
                          errors={errors}
                          id="help"
                        >
                          {this.renderOption()}
                        </DropDown>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <CustomInput
                          labelInfo={this.getLabelInfo("Full Name")}
                          type="text"
                          name="fullName"
                          id="fullName"
                          className={
                            errors && errors.fullName
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholder="Enter Name"
                          onChange={this.handleInput}
                          value={this.state.fullName}
                          errors={errors}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <CustomInput
                          labelInfo={this.getLabelInfo("Company Name")}
                          type="text"
                          className={
                            errors && errors.company
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="company"
                          id="company"
                          placeholder="Enter Company Name"
                          onChange={this.handleInput}
                          errors={errors}
                          value={this.state.company}
                          readOnly={true}
                          style={{ backgroundColor: "#eef1f5" }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <CustomInput
                          labelInfo={this.getLabelInfo("Email")}
                          type="text"
                          className={
                            errors && errors.email
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="email"
                          id="email"
                          placeholder="Enter  Email"
                          onChange={this.handleInput}
                          errors={errors}
                          value={this.state.email}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="button"
                          className="btn linear-gradient yr-submitbtn"
                          onClick={() => this.setState({ showCCModal: true })}
                        >
                          + Add Additional Email
                        </button>
                      </div>
                    </div>

                    {this.state.ccEmails.length > 0 && (
  <div className="form-group row">
    <div className="col-lg-12">
      <label className="form-control-label">CC Emails:</label>
      <div
        className="cc-email-list"
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          padding: "5px",
        }}
      >
        {this.state.ccEmails.map((email, index) => (
          <span
            key={index}
            className="email-item"
            style={{
              display: "flex",
              alignItems: "center",
              background: "none", 
              padding: "6px 12px",
              borderRadius: "20px",
              fontSize: "14px",
              color: "#333",
              border: "1px solid #ddd",
              boxShadow: "2px 2px 5px rgba(0,0,0,0.1)",
            }}
          >
            {email}
            <button
              type="button"
              className="delete-btn"
              onClick={() => this.removeEmail(email)}
              style={{
                background: "none",
                border: "none",
                color: "red",
                cursor: "pointer",
                marginLeft: "10px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <i className="fa fa-trash"></i> 
            </button>
          </span>
        ))}
      </div>
    </div>
  </div>
)}
                    <div className="form-group row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <CustomInput
                          labelInfo={this.getLabelInfo("Summary")}
                          type="text"
                          className={
                            errors && errors.summary
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="summary"
                          id="summary"
                          placeholder="Enter Summary"
                          onChange={this.handleInput}
                          errors={errors}
                          value={this.state.summary}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <CustomTextArea
                          labelInfo={this.getLabelInfo(
                            "Please describe how we can help you"
                          )}
                          className={
                            errors && errors.description
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          name="description"
                          id="description"
                          placeholder="Enter Message"
                          rows={5}
                          onChange={this.handleInput}
                          errors={errors}
                          value={this.state.description}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <label className="form-control-label">
                          You can upload screenshot here{" "}
                        </label>
                        <div
                          className="kt-dropzone dropzone"
                          action="inc/api/dropzone/upload.php"
                          id="m-dropzone-one"
                        >
                          <div
                            className="kt-dropzone__msg dz-message needsclick"
                            id="drop-container"
                          >
                            <CustomImageUploadField
                              addFiles={(file) => this.addFiles(file)}
                            />
                            <h3 className="kt-dropzone__msg-title">
                              Click / Drag and Drop Images Here
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="kt-portlet__foot">
                    <div className="kt-form__actions">
                      <div className="row">
                        <div className="col-lg-12 text-right">
                          <button
                            onClick={() => this.redirectUser()}
                            type="reset"
                            className="btn linear-gradient-cancel yr-cancelbtn mg-r"
                          >
                            <span> Cancel </span>
                          </button>
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn linear-gradient yr-submitbtn"
                          >
                            Create
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </form> */}
                </Form>

                {/*end::Form*/}
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="yr-mt-20">
                <img
                  src={
                    config.imageUrl +
                    "/assets/media/demos/create-ticket-img.png"
                  }
                  className="img-responsive margin-l-d-20"
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.showCCModal && (
  <MessageDialog
    show={this.state.showCCModal}
    title="Add Additional Email"
    message={
     
      <div className="row">
      <div
        className="col-sm-11"
        style={{ marginLeft: "15px" }}
      >
        <label>Enter Email (Using comma separated):</label>
        <input
          type="text"
          className="form-control"
          value={this.state.ccEmailInput}
          onChange={(e) => this.setState({ ccEmailInput: e.target.value })}
        />
        {this.state.ccEmailError && <p className="text-danger">{this.state.ccEmailError}</p>}
      </div>
      </div>
    }
    toggleModal={() => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
      const newEmails = this.state.ccEmailInput
          .split(",")
          .map((email) => email.trim())
          .filter((email) => email); // Remove empty values
  
      const invalidEmails = newEmails.filter((email) => !emailRegex.test(email));
  
      if (invalidEmails.length > 0) {
          this.setState({ ccEmailError: "Invalid email(s): " + invalidEmails.join(", ") });
          return;
      }
      // const filteredEmails = newEmails.filter(email => email !== this.state.email);
  
      // Merge new emails with existing ones & remove duplicates
      const mergedEmails = Array.from(new Set([...this.state.ccEmails, ...newEmails]));
  
      this.setState({
          ccEmails: mergedEmails,
          showCCModal: false,
          ccEmailInput: "",
          ccEmailError: "",
      });
  }}
  />
)}

      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company ? state.user.company : null,
  };
};
export default connect(mapStateToProps, {
  toggleNavigation,
  setPageTitle,
  setMessage,
})(CreateTicket);
