import React, { useState, useContext,useEffect } from "react";
import DataLayerModal from './DataLayerModal'; // Ensure this file exists
import { DataContext } from '../../../../../../../components/Integration/DataConnections/DataProvider';
import api from '../../../../../../../api';
import './DataConnectPage.css'
import ConfirmationModal from './ConfirmationModal'; // Import the styled confirmation modal

import {
    Message,
  Button,
  Table,
  Header,
  Divider,
} from "semantic-ui-react";


const DataConnectPage = (props) => {
	const [modalStatus, setModalStatus] = useState(false);
	const {updateTurboToolsWithapi,turboTools,loadTool,connections  } = useContext(DataContext);
	const [showErrortype,setshowErrortype] = useState({type:"",title:"",message:""});
	const [modalOpen, setModalOpen] = useState(false);
	const [tools,updateTools] = useState(undefined);
	const [selectedTool, setSelecteTool] = useState(null);
	const [formData, setFormData] = useState({
		id:null,
		connectionName: "",
		databasefortool:"",
		description: "",
		isAsync: false,
		properties: [],
		required:[]
	});

	//this will initialize the tool
	useEffect( () => {
		if(tools == undefined){
			loadTool(props.workspace_id)
		}
		updateTools(turboTools)
	},[turboTools]);

	//display this
	useEffect( () => {
		setTimeout(() => {
			setshowErrortype({type:"",title:"",message:""})
		}, 7000);
	},[showErrortype]);
	//this will open a modal where we can add new tool in databse and vapi also
	const addNewToolOpenModal = () => {
		setFormData({
			id:null,
			connectionName: "",
			description: "",
			databasefortool:"",
			isAsync: false,
			properties: [],
		});
		setModalStatus(true);
	}

	//this function open modal where we can edit existing tool
	const editformdata = ( conn ) => {
		let formdataasProp = {
			id:conn.id,
			connectionName: conn.tool_name,
			description: conn.description,
			isAsync: conn.async,
			properties: conn.properties,
			required:conn.required ?? [],
			databasefortool:conn.databasefortool ?? "",
		};
		setFormData(formdataasProp);
		setModalStatus(true);
	}


	// this function will delete tool from vapi along with own database
	const deleteformdata = ( ) => {
		let id = selectedTool;
		api.DatalayerandTooling.deleteNewturboTool(id).then((res) => {
			if(res.status != 200 ){
				loadTool(props.workspace_id);
				setshowErrortype({type:"positive",title:"Succes!",message:"Tool has been deleted succesfully."})
			}else{
				setshowErrortype({type:"negative",title:"Failed!",message:"Something went wrong."})
			}
			setModalOpen(false);
		})
	}
	// this function will display alert for succes and failure of updation and saving tool
	const messageAlert = () => {
		if(showErrortype.title != ""){
			return (
				showErrortype.title == 'negative' ? 
				<Message >
					<Message.Header>{showErrortype.title}</Message.Header>
					<p>{showErrortype.message}</p>
				</Message> : 
				<Message positive>
					<Message.Header>{showErrortype.title}</Message.Header>
					<p>{showErrortype.message}</p>
				</Message>
			)
		}else{
			return <></>
		}
		
	}
	// Open confirmation modal
	const openModal = (database) => {
		setSelecteTool(database.id);
		setModalOpen(true);
	};

	// Close the modal
	const closeModal = () => {
		setSelecteTool(null);
		setModalOpen(false);
	};
	return (
		<>
		<div style={{ margin: "0 auto", maxWidth: "90%" }}>
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }} >
				<Header as="h3" >
					Manage Tools
				</Header>
				<button
					color="blue"
					className=" btn linear-gradient yr-submitbtn"
					onClick={() => addNewToolOpenModal()}
				>
					Add New Tool
				</button>
			</div>
			<Divider />
			{messageAlert()}
			{/* Data Connections Table */}

			<Table celled compact style={{ tableLayout: "fixed", width: "100%" }}>
				<Table.Header>
					<Table.Row>
					<Table.HeaderCell style={{ width: "10%" }}>Sno</Table.HeaderCell>
					<Table.HeaderCell style={{ width: "40%" }}>Data Connection Name</Table.HeaderCell>
					<Table.HeaderCell style={{ width: "10%" }}>Async</Table.HeaderCell>
					<Table.HeaderCell style={{ width: "40%" }}>Actions</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{tools && tools.length > 0 && tools.map((conn,index) => (
					<Table.Row key={conn?.id}>
						<Table.Cell>{index+1}</Table.Cell>
						<Table.Cell>{conn.tool_name}</Table.Cell>
						<Table.Cell>{conn.async ? "On" : "Off"}</Table.Cell>
						<Table.Cell>
						<div style={{ display: "flex", gap: "10px" }}>
							<button type="button" className="btn btn-icon" onClick={() => editformdata(conn)} >
								<i class="la la-edit blue" title="Edit"></i>
							</button>
							<button type="button" class="btn btn-icon" /* onClick={ () => deleteformdata(conn.id)}  */onClick={() => openModal(conn)}>
								<i className="la la-trash red" title="Delete"></i>
							</button>
						</div>
						</Table.Cell>
					</Table.Row>
					))}
				</Table.Body>
			</Table>
			
			
		</div>
		{/* Custom Confirmation Modal */}
		<ConfirmationModal
			open={modalOpen}
			onClose={closeModal}
			onConfirm={deleteformdata}
		/>

		{modalStatus && (
			<DataLayerModal
			preformData={formData}
			open={modalStatus}
			connections={connections}
			updateConnectiontools={loadTool}
			onClose={() => setModalStatus(false)}
			onOpen={() => setModalStatus(true)}
			setshowErrortype={setshowErrortype}
			workspace_id={props.workspace_id}
			messageAlert={messageAlert}

			/>
		)}
		</>
	);
};

export default DataConnectPage;
