import React from "react";
import api from "../../../api";

import { Segment, Table, Pagination, Message } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import ConfirmationModal from "../../Integration/DataConnections/ConfirmationModal";


import ChatFilter from "./ChatFilter";
import { DatatableRow } from "./DatatableRow";
import { DatatablePageSizeSelect } from "./DatatablePageSizeSelect";
import Modal from "../ChatSetup/components/Modal";
import AdditionalCaptured from "./AdditionalCaptured";

import ViewDetails from "./ViewDetails";
import ViewNotes from "./ViewNotes";

import ChatStatus from "./ChatStatus";
import { CSVLink } from "react-csv";
import Notes from "./Notes";
import DialogBoxHipaaPin from "../DialogBoxHipaaPin";
import config from "../../../config/index";
import CustomErrorField from "../../Form/CustomErrorField";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomModel from "../CompletedChats/CustomModel";
import moment from 'moment';

const tableHeader = [
    {
        title: "Actions",
        width: "1",
        sort: "",
        checkbox: true,
    },
    {
        title: "Date",
        width: "3",
        sort: "created_at",
    },
    {
        title: "From number",
        width: "1",
        sort: "",
    },
    {
        title: "Call transcript",
        width: "2",
        sort: "",
    },
    {
        title: "Call recording",
        width: "1",
        sort: "",
    },
    {
        title: "Summary",
        width: "2",
        sort: "",
    },
    {
        title: "Transcript Status",
        width: "3",
        sort: "chat_status",
    },
    {
        title: "Team Notes",
        width: "3",
        sort: "",
    },

    {
        title: "AI call time",
        width: "1",
        sort: "",
    },

    {
        title: "Call Status",
        width: "1",
        sort: "",
    },

    {
        title: "Parsed Data",
        width: "2",
        sort: "",
    },
    {
        title: "Notifications",
        width: "2",
        sort: "",
    }
];

const queryParams = [
    "_limit",
    "_order",
    "_sort",
    "q",
    "_page",
    "chatstatus",
    "chatfrequency",
    "time_zone",
    "startdaterange",
    "enddaterange",
];
const twl_errs={13224:"Twilio does not support calling this number or the number is invalid",13223:"Twilio does not support calling this number or the number is invalid",13226:"Invalid country code",30005:"Unknown destination handset",30006:"Landline or unreachable carrier",30001:"Queue overflow",30003:"Unreachable destination handset",30014:"To attributes are Invalid"};

export default class DatatableList extends React.Component {
    #oneMonthAgo = moment().subtract(1, 'month').format('MM/DD/YYYY');
    #today =moment().format('MM/DD/YYYY');
    constructor(props) {
        super(props);
        this.state = {
            lists: [],
            _sort: "id",
            _page: 1,
            _order: "desc",
            _limit: 10,
            q: "",
            chatstatus: "all",
            chatfrequency: "daterange",
            startdaterange: this.#oneMonthAgo,
            enddaterange: this.#today,
            totalCount: 0,
            loading: false,
            modalstatus: false,
            modaltitle: null,
            modalbuttonmsg: {},
            modalType: null,
            modalfooter: "",
            modalBody: "",
            datatableHeader: null,
            datatableRows: null,
            modalErrorMsg: false,
            modalstatusAddEdit: false,
            modaldata: {},
            modalFormLoader: false,
            selectedOptions: [],
            accessModuleList: [],
            auto_id: "",
            modalTranscript: "",
            csvdata: [],
            hipaaValidateObject: {},
            modalstatusHIPAA: false,
            user: props.user,
            last_call_recording: '',
            reportcommnet: '',
            reporterror: false,
            responseError: false,
            reportstatus: '',
            modalBtn:true,
            oldTxt:'',
            custom_search:[],
            custom_searchdata:[],
            parsed_data:{},
            openCustomModal:false,
            curid:"",
            existdata:[],
            parsed_config:[],
            is_required:0,
            date_required:0,
            user_timezone:'',
            job_description:"",
            use_address:"",
            parsebtnName:"",
            requestedFiles:[],
            alert_msg:'',
            emails: [],
            emailList:[],
            errors: {},
            isModalOpen: false, selectedId: null,
        };
        if(this.props.user.user_id && this.props.user.user_id==721){
            console.log(tableHeader,tableHeader[10],'Parsed Data')
            let outcome_pos=10;
             if(tableHeader[outcome_pos].title=='Parsed Data'){
                tableHeader.splice(outcome_pos,0,{
                    title: "Call Outcome",
                    width: "3",
                    sort: "",
                });
            }
        }
        this.csvLink = React.createRef();
    }

    componentDidMount() {
        this.loadData({});
    }



    static directionConverter(order) {
        if (order === "asc") {
            return "ascending";
        } else if (order === "desc") {
            return "descending";
        } else {
            return null;
        }
    }

    handleSort = (clickedColumn) => {
        const { _sort, _order } = this.state;

        let newOrder = _order === "asc" ? "desc" : "asc";
        if (_sort !== clickedColumn) {
            newOrder = "asc";
        }

        this.loadData({
            _sort: clickedColumn,
            _page: 1,
            _order: newOrder,
        });
    };

    onChangeLimit = (event, data) => {
        if (data.value !== this.state._limit) {
            this.loadData({ _limit: data.value, _page: 1 });
        }
    };
    addEmailField = () => {
        this.setState((prevState) => ({
            emails: [...prevState.emails, { email: "", name: "" }]
        }), () => {
            this.updatemodelBody();
        });
    };
   removeEmailField = (index, event) => {
    if (event) event.preventDefault();
    if (event) event.stopPropagation();
    this.setState((prevState) => {
        const updatedEmails = [...prevState.emails];
        updatedEmails.splice(index, 1);
        return { 
            emails: updatedEmails,
            modalBtn: true  
        };
    }, () => {
        this.updatemodelBody();
    });
};
    handleEmailChange = (index, event) => {
        const newEmails = [...this.state.emails];
        newEmails[index].email = event.target.value;
        this.setState({ emails: newEmails,modalBtn:false}, () => {
            this.updatemodelBody();
        });
    };
    handleNameChange = (index, event) => {
        const newEmails = [...this.state.emails];
        newEmails[index].name = event.target.value;
        this.setState({ emails: newEmails,modalBtn:false }, () => {
            this.updatemodelBody();
        });
    };
    onSubmitFilter = (filter, val) => {

        if (filter == "chatfrequency") {
            this.setState({
                startdaterange: "",
                enddaterange: "",
            });
        }
        //if (filter !== this.state.q) {
        this.loadData({ [filter]: val, _page: 1 });
        // }
    };

    onSubmitFilterDateReange = (parmas) => {
        this.loadData({
            chatfrequency: parmas["chatfrequency"],
            startdaterange: parmas["startdaterange"],
            enddaterange: parmas["enddaterange"],
            _page: 1,
        });
    };

    onChangePage = (event, data) => {
        const { activePage } = data;
        if (activePage !== this.state._page) {
            this.loadData({ _page: activePage });
        }
    };

    modalClose = (flag, fm) => {
        this.setState({
            modalstatus: false,
            modalstatusAddEdit: false,
            modaltitle: "",
            modalmessage: "",
            modalbuttonmsg: {},
            modalType: "",
            modaldata: {},
            selectedOptions: [],
            emails: [],
        });
    };

    loadData = (params) => {
        const newState = Object.assign({}, this.state, params, { loading: false });
        queryParams.forEach(function (element) {
            if (!(element in params)) {
                params[element] = newState[element];
            }
        });

        let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
        let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
        let time_zone = clientTimeZone;

        params["time_zone"] = time_zone;

        const esc = encodeURIComponent;
        const query = Object.keys(params)
            .map((k) => esc(k) + "=" + esc(params[k]))
            .join("&");

        let totalCountQuery = "";
        if (params.q !== "") {
            totalCountQuery = `q=${params.q}`;
        }
        this.setState(newState, () => {
            this.setState({ loading: true });
            api.transcriptchatlist.getVoiceChatData(query).then((data) => {
                try {
                    let datatableRowstemp;
                    this.setState({ lists: data.data.list });

                    if (data.data.totalrecord) {

                        datatableRowstemp = this.state.lists.map((list, index) => (
                            <DatatableRow
                                channgeEvent={this.checkboxChangeEvent}
                                key={index}
                                list={list}
                                viewAdditionalCaptured={this.viewAdditionalCaptured}
                                viewDetails={this.viewDetails}
                                viewSummary={this.viewSummary}
                                viewRecording={this.viewRecording}
                                viewNotes={this.viewNotes}
                                viewNoteshtml={this.viewNotesHtml}
                                openHipaaRow={this.openHipaaRow}
                                viewPaerseData={this.viewPaerseData}
                                reGenerateParseData={this.reGenerateParseData}
                                notificationDetails={this.notificationDetails}
                                user_id={this.props.user.user_id}
                            />
                        ));
                    } else {
                        datatableRowstemp = (
                            <Table.Row>
                                <Table.Cell colSpan={7} textAlign="center">
                                    No record found.
                                </Table.Cell>
                            </Table.Row>
                        );
                    }
                    this.setState({
                        datatableRows: datatableRowstemp,
                        totalCount: data.data.totalrecord,
                        lists: data.data.list,
                        requestedFiles:data.data?.requested_file ? data.data?.requested_file:[],
                    });
                } catch (err) {
                    console.log(err);
                }
                const newState = Object.assign({}, this.state, params, {
                    loading: false,
                });
                this.setState(newState);
            });
        });
    };
    copyurl = (linkurl, logid = '') => {
        const base_url = config.serverBaseURL;
        let externalLink = `${base_url}/voice/recording/${btoa(logid)}`;
        navigator.clipboard.writeText(externalLink);
        const copyp = document.getElementById('copyurlsuccess');
        copyp.style.display = 'block';
        setTimeout( () => {
            copyp.style.display = 'none';
        }, 2000);
    }
    viewRecording = (rsid, call_recording_sid) => {
        if (!rsid) {
            this.setState({
                loading: false,
                modalstatus: true,
                modaltitle: "",
                modalfooter: "",
                modalbody: (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ fontWeight: 600, fontSize: 14, color: 'red' }}>Call recording not available.</span>
                    </div>

                ),
                modalTranscript: false
            }, () => {
                this.changeTableRowBackground(call_recording_sid);
            });
            return false;
        }
        try {
            const base_url = config.serverBaseURL;
            let externalLink = `${base_url}/voice/recording/${btoa(call_recording_sid)}`;
            this.setState({
                last_call_recording: `recording_play_${call_recording_sid}`,
                loading: false,
                modalstatus: true,
                modaltitle: "",
                modalfooter: "",
                modalbody: (
                    <>
                        <div style={{display: 'flex', justifyContent: 'space-between', columnGap: 20}}>
                            <div class="box-title">Call recording</div>
                            <div class="box-title" id="copyurlsuccess" style={{display: 'none', color: 'green'}}>URL Copied</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <div onClick={ () => this.copyurl(rsid, call_recording_sid)} title="Copy" className="alert" style={{backgroundColor: 'aliceblue', cursor: 'pointer', overflowWrap: 'anywhere', alignItems: 'center', columnGap: 14}}>
                                {externalLink} <i className="fa fa-copy"></i>
                            </div>
                            <audio key={`recording_play_${call_recording_sid}`} id={`recording_play_${call_recording_sid}`} controls>
                                <source src={`${rsid}`} />
                                <source src={`${rsid}`} />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    </>
                ),
                modalTranscript: false
            }, () => {
                this.changeTableRowBackground(call_recording_sid);
            });
        } catch (err) { }
    }

    translate = () => {
        this.setState({
            showenglish:!this.state.showenglish
        })
    }
    viewDetails = (auto_id) => {
        let recordlist = this.state.lists.findIndex(
            (list) => list.auto_id === auto_id
        );

        api.transcriptchatlist.viewVoiceChatDetails({ auto_id: auto_id, type: 'question_answer' }).then((data) => {
            try {
                this.setState({
                    loading: false,
                    modalstatus: true,
                    modaltitle: "",
                    modalfooter: "",
                    auto_id: auto_id,
                    custom_search:data.custom_search,
                    custom_searchdata:data.search_data,
                    parsed_data:data.parsed_data,
                    curid:auto_id,
                    existdata:data.exist_data,
                    parsed_config:data.parsedconfig,
                    is_required:data.is_required,
                    date_required:data.date_required,
                    user_timezone:data.timezone,
                    job_description: this.state.lists[recordlist].summary,
                    use_address:data.use_address,
                    modalbody: (
                        <ViewDetails
                            detaildata={data.data.data}
                            detaildataTrans={data.data.dataTrans}
                            auto_id={auto_id}
                            updatenote={this.updateNote}
                            custom_search={data.data.custom_search}
                            openCustomPopup={this.openCustomPopup}
                            showtranslate={true}
                        ></ViewDetails>
                    ),
                    modalTranscript: (
                        <ChatStatus
                            chatRef="live_chat_log"
                            auto_id={auto_id}
                            selected_chat={data.data.chatstatus}
                            list={[
                                "Change Status",
                                {
                                    label: "Customer Contacted",
                                    submenu: [
                                        { label: "Customer Contacted", value: 1 },
                                        { label: "Contacted attempted-left message", value: 4 },
                                        { label: "Contacted attempted-unable to leave message", value: 5},
                                    ],
                                },
                                "Customer Cancelled",
                                "Customer Serviced",
                                { label: "Spam", value: 6 },
                                { label: "Abandoned", value: 7 },
                            ]}
                        />
                    ),
                }, () => {
                    this.changeTableRowBackground(auto_id);
                });
            } catch (err) { }
        });
    };

    changeTableRowBackground = (auto_id) => {
        const allTableRows = document.querySelectorAll('.table-row-ai');
        allTableRows.forEach((row) => {
            row.classList.remove('active');
        });
        const activeRow = document.querySelector(`.tr__${auto_id}`);
        activeRow.classList.add('active');
    }

    getDetails = (auto_id) => {
        this.setState({loading:true});  
        let recordlist = this.state.lists.findIndex(
            (list) => list.auto_id === auto_id
        );

        api.transcriptchatlist.viewVoiceChatDetails({ auto_id: auto_id, type: 'question_answer' }).then((data) => {
            try {
                if(data.status){
                    this.setState({
                        loading: false,
                        custom_search:data.custom_search,
                        custom_searchdata:data.search_data,
                        parsed_data:data.parsed_data,
                        curid:auto_id,
                        existdata:data.exist_data,

                    });
                    return true;
                }
                return false;

            } catch (err) {
                return false;
            }
        });
    };

    reGenerateParseData = (logid) => {
        this.setState({
            loading : true
        })
        api.transcriptchatlist.reGenerateParseData({logid: logid}).then((res) => {
            console.log(res);
            if (res.status) {
                const allList = this.state.lists;
                const findIndex = allList.findIndex((item, index) => item.auto_id == logid);
                allList[findIndex].custom_capture = res.parse_data;
                this.setState({
                    lists: allList,
                    loading : false             
                });
            }else{
                this.setState({                 
                    loading : false   
                });
            }
            toast.success(res.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",

            });

        }).catch((err) => {
            console.log(err);
            this.setState({
                loading : false
            })
        })
    }

    viewPaerseData = (data,id) => {
        this.setState({loading:true,parsebtnName:"parsebtn"});
        let recordlist = this.state.lists.findIndex(
            (list) => list.auto_id === id
        );
        if (data) {
            try {
                data = JSON.parse(data);
            } catch (error) {
                data = {}
            }
            data = data == null ? {} : data;

            api.transcriptchatlist.viewVoiceChatDetails({ auto_id: id, type: 'question_answer' }).then((data1) => {
                try {
                    let stateobj={
                        loading: false,
                        modalstatus: true,
                        modaltitle: "",
                        modalfooter: "",
                        custom_search:data1.custom_search,
                        custom_searchdata:data1.search_data,
                        parsed_data:data1.parsed_data,
                        curid:id,
                        existdata:data1.exist_data,
                        parsed_config:data1.parsedconfig,
                        is_required:data1.is_required,
                        date_required:data1.date_required,
                        user_timezone:data1.timezone,
                        job_description: this.state.lists[recordlist].summary,
                        use_address:data1.use_address,
                        modalbody: (
                            Object.keys(data).length  ? 
                            <Table id="additional_captured">
                                <Table.Body>
                                    {Object.keys(data).map(function(key,index){
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{key}</Table.Cell>
                                                    <Table.Cell>{(typeof data[key] == 'string') ? data[key] : JSON.stringify(data[key])}</Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                        }
                                    </Table.Body>
                                </Table> : <>
                                    <h2>No data available.</h2>
                                </>
                        ),
                        modalTranscript: false
                    };

                    this.setState(stateobj,() => {this.changeTableRowBackground(id);});
                    return false;

                } catch (err) {

                }
            });

        }else{
            this.setState({
                loading: false,
                modalstatus: true,
                modaltitle: "",
                modalfooter: "",
                modalbody: (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{ fontWeight: 600, fontSize: 14, color: 'red' }}>No data available.</span>
                    </div>

                ),
                modalTranscript: false
            }, () => {
                this.changeTableRowBackground(id);
            });
            return false;
        }
    }

    viewSummary = (auto_id) => {
        api.transcriptchatlist.viewVoiceChatDetails({ auto_id: auto_id, type: 'summary' }).then((data) => {
            try {
                this.setState({
                    loading: false,
                    modalstatus: true,
                    modaltitle: "",
                    modalfooter: "",
                    modalbody: (
                        <ViewDetails
                            detaildata={data.data.data}
                            auto_id={auto_id}
                            summary={true}
                            showtranslate={false}
                        ></ViewDetails>
                    ),
                    modalTranscript: false,
                }, () => {
                    this.changeTableRowBackground(auto_id);
                });
            } catch (err) { }
        });
    }

    deleteNotes = ( id ) => {
        api.transcriptchatlist.deleteNotes({ auto_id: id }).then((data) => {
            
            this.setState({
                loading: false,
                modalstatus: false,
                modaltitle: "Team Notes",
            },this.loadData({}))
        });

    }
    viewNotesHtml = async (auto_id) => {
        let recordlist = this.state.lists.findIndex(
            (list) => list.auto_id === auto_id
        );
        api.transcriptchatlist.fetchparticularNotes({ auto_id: auto_id }).then((data) => {
            this.setState({
                loading: false,
                modalstatus: true,
                modaltitle: "Team Notes",
                modalfooter: "",
                modalbody: (
                    <ViewNotes
                        auto_id={auto_id}
                        data={data}
                        comapny={this.state.lists[recordlist].company}
                        chatlogo={this.state.lists[recordlist].chatLogo}
                        deleteNotes = {this.deleteNotes}
                    ></ViewNotes>
                ),
                modalTranscript: false,
            }, () => {
                this.changeTableRowBackground(auto_id);
            });
        })


    }

    updateNote = (auto_id, value) => {


        let datalist = this.state.lists;

        let recordlist = this.state.lists.findIndex(
            (list) => list.auto_id === auto_id
        );

        datalist[recordlist]["team_notes"] = value;
        this.setState({
            datatableRows: datalist.map((list, index) => (
                <DatatableRow
                    channgeEvent={this.checkboxChangeEvent}
                    key={index}
                    list={list}
                    viewAdditionalCaptured={this.viewAdditionalCaptured}
                    viewDetails={this.viewDetails}
                    viewSummary={this.viewSummary}
                    viewRecording={this.viewRecording}
                    viewNoteshtml={this.viewNotesHtml}
                    openHipaaRow={this.openHipaaRow}
                    reGenerateParseData={this.reGenerateParseData}
                    user_id={this.props.user.user_id}

                />
            )),
            lists: datalist,
        });
    };

    viewAdditionalCaptured = (auto_id) => {
        let recordlist = this.state.lists.findIndex(
            (list) => list.auto_id === auto_id
        );

        let modaldata = this.state.lists[recordlist]["additionl_captured"];

        this.setState({
            loading: false,
            modalstatus: true,
            modaltitle: "Additional Captured Fields",
            modalfooter: "",
            modalbody: (
                <AdditionalCaptured
                    additional_captured={modaldata}
                ></AdditionalCaptured>
            ),
            modalTranscript: false,
        });
    };

    viewNotes = (auto_id) => {
        try {
            let notes_data = "";
            this.setState({
                loading: false,
                modalstatus: true,
                modaltitle: "Team Notes",
                modalfooter: "",
                modalbody: (
                    <Notes
                        auto_id={auto_id}
                        commentform="false"
                        tableref="chat_log"
                        updatenote={this.updateNote}
                    ></Notes>
                ),
                modalTranscript: false,
            });
        } catch (err) { }
    };

    modalFooter = (type) => { };

    modalClose = () => {
        const recordingDiv = document.getElementById(this.state.last_call_recording);
        if (recordingDiv) {

            recordingDiv.pause();
            // recordingDiv.src = '';
        }
        this.setState({
            modalstatus: false,
            parse_data:[],
            custom_search:[],
            custom_searchdata:[],
            parsebtnName:"",
            emails: [],

        });
    };

    modalFooter = (id, type) => {
        return (
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => this.modalClose()}
                    className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                    data-dismiss="modal"
                >
                    <span>No</span>
                </button>
                <button
                    type="button"
                    onClick={() => this.confirmDelete(this.state.modaldata.id)}
                    className="btn linear-gradient yr-submitbtn btn-sm"
                >
                    <span>Yes</span>
                </button>
            </div>
        );
    };

    modalBody = () => {
        if (this.state.modalType == "job_description") {
            this.getJobDescriptionView(this.state.auto_id);
        }

        if (this.state.modalType == "additional_captured") {
            this.getAdditionalCapturedView(this.state.auto_id);
        }

        if (this.state.modalType == "notes") {
            this.getTeamNotesView(this.state.auto_id);
        }
    };

    getJobDescriptionView = (id) => { };
    getAdditionalCapturedView = (id) => {
        let recordlist = this.state.lists.findIndex((list) => list.auto_id === id);

        let modaldata = this.state.lists[recordlist]["additionl_captured"];

        Object.keys(modaldata).map(function () { });
    };
    getTeamNotesView = (id) => { };

    downloadCSV = (event, done) => {
        let params = {};
        let duration=0;
        params["chatstatus"] = this.state.chatstatus;
        params["chatfrequency"] = this.state.chatfrequency;
        params["strsearch"] = this.state.q;
        params["startdaterange"] = this.state.startdaterange;
        params["enddaterange"] = this.state.enddaterange;
        var end = moment(this.state.enddaterange);
        var now = moment(this.state.startdaterange);
        duration = end.diff(now,'days');
        let list = this.state.lists;
        let selected_arr = [];
        list.filter(function (key) {
            if (key["checked"] == true) {
                selected_arr.push(key["auto_id"]);
            }
        });
        this.setState({ loading: true });
        let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
        let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
        let time_zone = clientTimeZone;
        let params1 = {...params, user_id: this.props.user.user_id,chatBotId: this.props.user.chatBot_id,timezone: time_zone};
        params["timezone"]=time_zone;
        if((this.state.chatfrequency==="daterange" && duration>90) || this.state.chatfrequency=='pastyear' ){
            api.transcriptchatlist.getvoiceChatExport(params1).then((data) => {
                if(data.status){
                    this.setState({loading: false,alert_msg:'The CSV file will be available for download soon. Please refresh the page after a few minutes.',requestedFiles:[]});
                    setTimeout(() => {
                        this.setState({alert_msg:''});
                    }, 5000);
                }

            });

        }else{
            params["selectallcheckbox"] = selected_arr.length > 0 ? "false" : "true";
            params["chatidscheck"] = JSON.stringify(selected_arr);
            api.transcriptchatlist.getVoiceCSVData(params).then((data) => {
                try {
                    this.setState(
                        {
                            csvdata: data.data.data,
                        },
                        function () {
                            setTimeout(
                                function () {
                                    document.querySelector("#phonechatcsv").click();
                                    this.setState({ loading: false });
                                }.bind(this),
                                1000
                            );
                        }
                    );
                } catch (err) { }
            });
        }



    };

    // checkRequestedFiles=()=>{
    //  api.chatlist.checkrequestedfiles({type:'call'}).then((response)=>{
    //      if(response.data.length){
    //          console.log("data received",response.data);
    //          clearInterval(this.state.timeinterval);
    //          this.setState({alert_msg:'',loading:false,requestedFiles:response.data,timeinterval:null});
    //      }
    //  });
    //  }

    openCustomPopup=(search)=>{
        this.setState({openCustomModal:true,modalstatus:false});
      }
    
      closeCustomPopup=()=>{
        this.setState({openCustomModal:false,parsed_data:[],custom_search:[],custom_searchdata:[],curid:"",existdata:[]});
    }

    checkAll = (e) => {
        let datalist = this.state.lists;
        datalist.map(function (list, index) {
            list["checked"] = e.target.checked;
        });
        this.setState({
            datatableRows: datalist.map((list, index) => (
                <DatatableRow
                    channgeEvent={this.checkboxChangeEvent}
                    key={index}
                    list={list}
                    viewAdditionalCaptured={this.viewAdditionalCaptured}
                    viewDetails={this.viewDetails}
                    viewSummary={this.viewSummary}
                    viewRecording={this.viewRecording}
                    viewNotes={this.viewNotes}
                    viewNoteshtml={this.viewNotesHtml}
                    openHipaaRow={this.openHipaaRow}
                    reGenerateParseData={this.reGenerateParseData}
                    user_id={this.props.user.user_id}

                />
            )),
            lists: datalist,
        });
    };

    checkboxChangeEvent = (auto_id) => {
        let datalist = this.state.lists;
        let recordlist = datalist.findIndex((list) => list.auto_id === auto_id);

        if (datalist[recordlist]["checked"]) {
            datalist[recordlist]["checked"] = false;
        } else {
            datalist[recordlist]["checked"] = true;
        }

        this.setState({
            datatableRows: datalist.map((list, index) => (
                <DatatableRow
                    channgeEvent={this.checkboxChangeEvent}
                    key={index}
                    list={list}
                    viewAdditionalCaptured={this.viewAdditionalCaptured}
                    viewDetails={this.viewDetails}
                    viewSummary={this.viewSummary}
                    viewRecording={this.viewRecording}
                    viewNotes={this.viewNotes}
                    viewNoteshtml={this.viewNotesHtml}
                    openHipaaRow={this.openHipaaRow}
                    reGenerateParseData={this.reGenerateParseData}
                    user_id={this.props.user.user_id}

                />
            )),
            lists: datalist,
        });

        //  let array=this.state.checkedboxes;
        // const index = this.state.checkedboxes.indexOf(auto_id);
        // if (index > -1) {
        //   array.splice(index, 1);
        // }else{
        //   array.push(auto_id)
        // }

        // this.setState({
        //   checkedboxes:array
        // });
    };

    openHipaaRow = (auto_id) => {
        this.setState({ loading: true })
        api.manageUser.checkHipaaAccount({ user_id: this.props.user.user_id, email: this.props.user.email }).then((data) => {
            try {
                if (data.data.data.subaccount_id == "found") {
                    var type = "validate";
                } else {
                    var type = "register";
                }
                this.setState({
                    loading: false,
                    modalstatusHIPAA: true,
                    modaltitle:
                        "To view sensitive chat information, please enter your authorize email and PIN",
                    hipaaValidateObject: {
                        userId: this.props.user.user_id,
                        userType: "User",
                        via: "user",
                        auto_id: auto_id,
                        pin_email: this.props.user.email,
                        refId: auto_id,
                        chat_section: "voicecalllist",
                        type: type

                    },
                });
            } catch (err) { }
        });


    };

    openHipaaAfterRegister = auto_id => {
        this.setState({
            loading: false,
            modalstatusHIPAA: true,
            modaltitle:
                "To view sensitive chat information, please enter your authorize email and PIN",
            hipaaValidateObject: {
                userId: this.props.user.user_id,
                userType: "User",
                via: "user",
                auto_id: auto_id,
                pin_email: this.props.user.email,
                refId: auto_id,
                chat_section: "chatlist",
                type: "validate"

            },
        });
    }

    onValidHipaaSubmit = (auto_id) => {
        let datalist = this.state.lists;

        let recordlist = this.state.lists.findIndex(
            (list) => list.auto_id === auto_id
        );


        datalist[recordlist]["hippaOn"] = "none";
        this.setState({
            datatableRows: datalist.map((list, index) => (
                <DatatableRow
                    channgeEvent={this.checkboxChangeEvent}
                    key={index}
                    list={list}
                    viewAdditionalCaptured={this.viewAdditionalCaptured}
                    viewDetails={this.viewDetails}
                    viewSummary={this.viewSummary}
                    viewRecording={this.viewRecording}
                    viewNotes={this.viewNotes}
                    viewNoteshtml={this.viewNotesHtml}
                    openHipaaRow={this.openHipaaRow}
                    viewPaerseData={this.viewPaerseData}
                    reGenerateParseData={this.reGenerateParseData}
                    notificationDetails={this.notificationDetails}
                    user_id={this.props.user.user_id}
                />
            )),
            lists: datalist,
        });
        this.setState({
            chat: this.state.chat,
            chatLeftList: this.state.chatLeftList,
            modalstatusHIPAA: false,
            name: this.state.name,
        });
    }

    hipaaModalClose = e => {
        this.setState({
            modalstatusHIPAA: false,
        });
    }

    handleChangereport=(e)=>{
        let btn=true;
        let newtxt=e.target.value.trim();
        if(newtxt!==this.state.oldTxt){
           btn=false;
        }
        if(newtxt.length===0){
            btn=true;
        }
        
        this.setState({reportcommnet:newtxt,reporterror:false,modalBtn:btn},()=>{ this.updatemodelBody();});
    }

    updatemodelBody = () => {
        let body = (<form id="notesForm1" method="post">
            <Message
                color={this.state.responseError ? "red" : "teal"}
                style={{
                    display: this.state.reportstatus?.length ? "block" : "none",
                    marginTop: 30,
                }}
            >
                {this.state.reportstatus}
            </Message>
            Please provide additional details why this chat needs training?
            <div className="form-group">
                <textarea rows="5" onChange={(e) => this.handleChangereport(e)} name="reportcommnet" id="notes" placeholder="Write notes here ..." defaultValue={this.state.reportcommnet} className={
                    this.state.reporterror
                        ? "form-control is-invalid"
                        : "form-control"}></textarea>
                {this.state.reporterror && <CustomErrorField
                    message="This field is required"
                    id="help-error"
                    className="error invalid-feedback"
                />
                }
            </div>
            {Array.isArray(this.state.emailList) && this.state.emailList.length > 0 && (
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <strong style={{ fontSize: "16px", color: "#000", marginBottom: "5px", display: "block" }}>
                        The emails that will be notified when a resolution takes place:
                    </strong>
                    <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                        {this.state.emailList.map((item, index) => (
                            <li
                                key={index}
                                style={{
                                    padding: "8px 0",
                                    fontSize: "14px",
                                    color: "#333",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd"
                                }}
                            >
                                <span>{item.email} | {item.name}</span>
                                <button 
                                  onClick={(event) => this.openDeleteModal(event,item.id)}
                                    style={{
                                        border: "none",
                                        background: "transparent",
                                        cursor: "pointer",
                                        color: "red",
                                        fontSize: "18px",
                                        marginLeft: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "5px"
                                    }}
                                    title="Delete"
                                >
                                    <i className="la la-trash red" title="Delete"></i> {/* Font Awesome Trash Icon */}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="form-group">
                <button
                    type="button"
                    onClick={this.addEmailField}
                    style={{ width: '40%', marginTop: '20px', marginBottom: '20px' }}
                    className="btn linear-gradient yr-submitbtn btn-md sbold btn-block"
                >
                 + Add Additional Email
                </button>
            </div>
            <div style={{ marginTop: "5px" }} className="form-group">
                {this.state.emails.map((item, index) => (
                    <div key={index} style={{ display: "flex", flexDirection: "column", gap: "5px", marginBottom: "10px" }}>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <div style={{ flex: 1 }}>
                                <input
                                    type="text"
                                    placeholder="Enter Name"
                                    value={item.name}
                                    onChange={(e) => this.handleNameChange(index, e)}
                                    style={{ width: "100%", padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
                                />
                                {this.state.errors[index]?.name && (
                                    <small style={{ color: "red", display: "block", marginTop: "3px" }}>
                                        {this.state.errors[index].name}
                                    </small>
                                )}
                            </div>

                            <div style={{ flex: 1 }}>
                                <input
                                    type="email"
                                    placeholder="Enter Email"
                                    value={item.email}
                                    onChange={(e) => this.handleEmailChange(index, e)}
                                    style={{ width: "100%", padding: "8px", borderRadius: "5px", border: "1px solid #ccc" }}
                                />
                                {this.state.errors[index]?.email && (
                                    <small style={{ color: "red", display: "block", marginTop: "3px" }}>
                                        {this.state.errors[index].email}
                                    </small>
                                )}
                            </div>

                            {this.state.emails.length && (
                                <button
                                    style={{
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                    }}
                                    type="button"
                                    className="icon-btn-spl"
                                    onClick={(event) => this.removeEmailField(index, event)}
                                >
                                    <i className="la la-trash red" style={{ position: "relative", top: "-5px" }} title="Delete"></i>
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className="form-group">
                <button
                    disabled={this.state.modalBtn}
                    type="button"
                    onClick={(e) => {
                        this.setState({ modalBtn: true }, () => {
                            this.updatemodelBody();
                        });
                        this.saveReport(e);
                    }}
                    style={{ width: '100%', marginTop: '20px' }}
                    className="btn linear-gradient yr-submitbtn btn-md sbold btn-block"
                >
                    {this.state.loading ? "Processing..." : "Submit"}
                </button>
            </div>
        </form>
        );

        this.setState({ modalbody: body, loading: false, errors: {} });
        return body;
    };


    saveReport = (e) => {
        e.preventDefault();
        let note = this.state.reportcommnet;

        this.setState({ loading: true, modalBtn: true });
        this.setState({ errors: {} });

        let errors = {};

        this.state.emails.forEach((item, index) => {
            if (!item.name.trim()) {
                errors[index] = { ...errors[index], name: "This is a required field." };
            }
            if (!item.email.trim()) {
                errors[index] = { ...errors[index], email: "This is a required field." };
            } else if (!/^\S+@\S+\.\S+$/.test(item.email)) {
                errors[index] = { ...errors[index], email: "Invalid email format" };
            }
        });

        if (Object.keys(errors).length > 0) {
            this.setState({ errors, loading: false });
            return;
        }

        if (note.length !== 0) {
            const payload = {
                id: this.state.auto_id,
                report: note,
                emails: this.state.emails.map(item => item.email),
                names: this.state.emails.map(item => item.name),
            };

            api.chatlist.savechatReportVoice(payload).then((data) => {
                try {
                    if (data.status) {
                        this.setState({
                            reportcommnet: '',
                            auto_id: '',
                            reporterror: false,
                            responseError: false,
                            modaltitle: "Report Call For Training",
                            reportstatus: data.message,
                            loading: false,
                            emails: [],
                        });

                        setTimeout(() => {
                            this.setState({ modalstatus: false, reportstatus: "" });
                        }, 3000);
                    }else{
                        this.setState({
                            responseError: true,
                            modaltitle: "Report Call For Training",
                            reportstatus: data.message,
                            loading: false,
                            reporterror: false,
                        });
                        setTimeout(() => {
                            this.setState({ modalstatus: true, reportstatus: "", responseError: false, });
                        }, 3000);
                    }
                    this.updatemodelBody();
                } catch (err) {
                    console.error("Error saving report:", err);
                }
            });
        } else {
            this.setState({ reporterror: true, loading: false, responseError: false, });
            this.updatemodelBody();
        }
    };
    notificationDetails=(id)=>{
        this.setState({loading:true});
        let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
        let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
        let time_zone = clientTimeZone;
        api.transcriptchatlist.viewVoiceLog({ auto_id: id,timezone:time_zone}).then((data1) => {
            try {
                console.log(data1.data);
                let stateobj={
                    loading: false,
                    modalstatus: true,
                    modaltitle: "",
                    modalfooter: "",
                    modalbody: (
                        Object.keys(data1?.data).length  ? 
                        <div className="row tracklogtable" style={{height:'42vh',overflow:'hidden auto',width:'565px'}}><Table id="additional_captured" style={{maxHeight:'400px',overflowY:'Auto'}}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Name/Email or Number</Table.HeaderCell>
                                        <Table.HeaderCell>Type</Table.HeaderCell>
                                        <Table.HeaderCell>Status</Table.HeaderCell>
                                        <Table.HeaderCell>Date/Time</Table.HeaderCell>
                                        <Table.HeaderCell>Viewed Time</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                {data1?.data.map(function(arr,index){
                                        let log=typeof arr?.log!='undefined' && typeof arr?.log=='string' ? JSON.parse(arr?.log): arr?.log;
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell>{arr?.name}/{arr?.email_number}</Table.Cell>
                                                <Table.Cell>{arr?.type=='Sms'? 'SMS':arr?.type}</Table.Cell>
                                                {arr?.status!=="Undelivered" && arr?.status!=="Failed" ? <Table.Cell>{arr?.status}</Table.Cell>:
                                                 <Table.Cell>{arr?.status}&nbsp;<br/>{isNaN(log?.ErrorCode) ?
                                                    <span><strong>{log?.ErrorCode}</strong></span>:
                                                    typeof twl_errs[log?.ErrorCode]!=='undefined' ? <b>{twl_errs[log?.ErrorCode]}</b>:<span>Error:<a  rel="noreferrer" title="Check error message" target="_blank" href={`https://www.twilio.com/docs/api/errors/${log?.ErrorCode}`}>{typeof twl_errs[log?.ErrorCode]!=='undefined'? twl_errs[log?.ErrorCode]:log?.ErrorCode}</a></span>}</Table.Cell>}
                                                <Table.Cell>{arr?.updatedat}</Table.Cell>
                                                <Table.Cell>{arr?.viewed_time}</Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                    }
                                </Table.Body>
                            </Table></div> : <>
                                <h2>No data available.</h2>
                            </>
                    ),
                    modalTranscript: false
                };

                this.setState(stateobj,() => {this.changeTableRowBackground(id);});
                return false;

            } catch (err) {

            }
        });

    }


    showtrainlogModal = (id) => {
        this.setState({ loading: true });
        api.chatlist.getTrainingLogVoice("id=" + id).then((data) => {
            try {
                let msg = data.data;
                let users = data.users || [];
                let emails = users.map(user => ({
                    id: user.id || "",
                    email: user.email || "",
                    name: user.name || ""
                }));
                this.setState({ reportcommnet: msg, loading: false,oldTxt:msg, emailList: emails, });
                let body = this.updatemodelBody();
                this.setState({
                    loading: false,
                    modalstatus: true,
                    modaltitle: "Report Call For Training",
                    modalfooter: "",
                    modalbody: body,
                    responseError: false,
                    reporterror: false,
                    modalTranscript: false,
                });
                console.log(this.state.emailList);
            } catch (err) { }
        });
    }

    // Open modal and store selected user ID
    openDeleteModal = (event,id) => {
        event.preventDefault();
        this.setState({ isModalOpen: true, selectedId: id });
      };
    
    
      confirmDeleteUser = () => {
        const { selectedId, emailList } = this.state;
        if (!selectedId) return;
    
        api.manageUser
            .deleteReportedchatListMember({ id: selectedId })
            .then(() => {
                const updatedList = emailList.filter((list) => list.id !== selectedId);
                this.setState({
                    emailList: updatedList,
                    isModalOpen: false, 
                    selectedId: null, 
                    modalstatus: true,
                    responseError: false,
                    reportstatus: "The email has been removed successfully.",
                }, () => {
                    setTimeout(() => {
                        this.setState({ modalstatus: false, reportstatus: "",emails: [] });
                    }, 3000);
                });
                this.updatemodelBody();
            })
            .catch((error) => {
                console.error("Error deleting user:", error);
                this.setState({ responseError: true, modalstatus: true, isModalOpen: false });
            });
    };
    
    render() {
return (
            <div>
<ConfirmationModal
          open={this.state.isModalOpen}
          onClose={() => this.setState({ isModalOpen: false, selectedId: null })}
          onConfirm={this.confirmDeleteUser}
        />
                {this.state.alert_msg && <div className="alert alert-success">{this.state.alert_msg}</div>}
                <ChatFilter
                    filter={{
                        chatstatus: this.state.chatstatus,
                        chatfrequency: this.state.chatfrequency,
                        strsearch: this.state.q,
                        startdaterange: this.state.startdaterange,
                        enddaterange: this.state.enddaterange,
                    }}
                    totalCount={this.state.totalCount}
                    onSubmitFilter={this.onSubmitFilter}
                    loading={this.state.loading}
                    onSubmitFilterDateReange={this.onSubmitFilterDateReange}
                    csvdata={this.state.csvdata}
                    dowloadcsv={this.downloadCSV}
                    requestedFiles={this.state.requestedFiles}
                    showDarerangeErrorMessage={this.showDarerangeErrorMessage}
                />
    <Segment style={{ width: "100%",maxWidth: "calc(96vw)" }}>
                    {this.state.loading && <div className="ui loading form"></div>}

                    <DatatableTable
                        lists={this.state.lists}
                        datatableHeader={tableHeader}
                        datatableRows={this.state.datatableRows}
                        totalCount={this.state.totalCount}
                        totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
                        currentPage={this.state._page}
                        onChangePage={this.onChangePage}
                        column={this.state._sort}
                        direction={DatatableList.directionConverter(this.state._order)}
                        handleSort={this.handleSort}
                        onChangeLimit={this.onChangeLimit}
                        limit={this.state._limit.toString()}
                        checkAll={this.checkAll}
                    />
                </Segment>
                <DatatablePageSizeSelect
                    limit={this.state._limit.toString()}
                    onChangeLimit={this.onChangeLimit}
                />{" "}
                Showing 1 - {this.state._limit.toString()} of {this.state.totalCount}
                <Pagination
                    totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
                    activePage={this.state._page}
                    onPageChange={this.onChangePage}
                />
                <Modal
                    show={this.state.modalstatus}
                    footer={this.state.modalfooter}
                    onclose={this.modalClose}
                    title={this.state.modaltitle}
                    body={this.state.modalbody}
                    modaltranscript={this.state.modalTranscript}
                    isgpt={true}
                    showtrainlogModal={this.showtrainlogModal}
                    auto_id={this.state.auto_id}
                    loading={this.state.loading}
                    custom_search={this.state.custom_search}
                    openCustomPopup={this.openCustomPopup}
                    parsed_data={typeof this.state.parsed_data!="undefined" ? this.state.parsed_data : {}}
                    parsebtnName={this.state.parsebtnName ?? ""}
                />
                <CSVLink
                    id="phonechatcsv"
                    data={this.state.csvdata}
                    filename="phonechat.csv"
                    className="hidden"
                    target="_blank"
                />
                <DialogBoxHipaaPin
                    email={this.props.user.email}
                    obj={this.state}
                    modalClose={this.hipaaModalClose}
                    onValidHipaaSubmit={this.onValidHipaaSubmit}
                    openHipaaAfterRegister={this.openHipaaAfterRegister}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
 {(typeof this.state.custom_search!="undefined" && typeof this.state.custom_searchdata!="undefined" && typeof this.state.parsed_data!="undefined" )  && <CustomModel
                    show={this.state.openCustomModal}
                    modalClose={this.closeCustomPopup}
                    custom_search={this.state.custom_search}
                    custom_searchdata={this.state.custom_searchdata}
                    parsed_data={this.state.parsed_data}
                    exist_data={this.state.existdata}
                    title="Push Data Manually"
                    curid={this.state.curid}
                    module='voice'
                    parsed_config={this.state.parsed_config}
                    is_required={this.state.is_required}
                    date_required={this.state.date_required}
                    time_zone={this.state.user_timezone}
                    job_description={this.state.job_description}
                    use_address={this.state.use_address}
                />}
            </div>
        );
    }
}
