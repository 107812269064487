import React from "react";
import {  Message,Dropdown,Segment,Checkbox } from "semantic-ui-react";
import Validator from "validator";
import api from "../../api";
import Switch from "react-switch";
import utilFunc from "../../util_funs";
import { Form } from "formsy-semantic-ui-react";
import "./integration.css"
import ConfirmDialog from "../Modal/ConfirmDialog";
import validationRule from "../../validation";
import GridList from "../Grid";


class Webhook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      webhook: "",
      webhookStatus: false,
      successMessage: null,
      errors: null,
      contentType: "multipart/form-data",
      workspaceId: null,
      web_workspaceId: null,
      workspaceBranches: null,   
      web_workspaceBranches: null,    
      parsed_values: null,
      web_parsed_values: null,
      selectedWebhook: [],
      dynamicOptions: {}, // To store the options for each field dynamically
      selectedValues: {}, // To store selected values for dynamic dropdowns,
      web_dynamicOptions: {}, // To store the options for each field dynamically
      web_selectedValues: {}, // To store selected values for dynamic dropdowns
      required_settings: 0,
      confirmDialog:false,
      webhook_id:null,
      errorMessage: null,
      dataLoading: null,
      webhook_info:{},
      refreshGrid:false,
      disable: false,

     
    };
    this.formRef = React.createRef();
        this.columnLists = [
          { text: "S.No", key: "id", sort: false },
          { text: "Webhook Url", key: "webhook_url" },
          {text: "Webhook Status", key: "status"},
          { text: "Content Type", key: "content_type" },
          {text: "Voice", key: "voice_on"},
          { text: "Voice Required Setting", key: "required_setting" },
          {text: "Chat", key: "web_on"},
          { text: "Chat Required Setting", key: "web_required_setting" },
          {
            text: "Action",
            key: "action",
            sort: false,
            event: [
                {
                    key: "editCredentials",
                    params: ["id"],
                    func: (id) =>
                        this.editCredentials(id),
                },
                {
                    key: "deleteCredentials",
                    params: ["id"],
                    func: (id) => this.confirmAction(id),
                },
            ],
        },
        ];
  }
  confirmAction = (id) =>
    this.setState({ webhook_id:id, confirmDialog: true });
  toggleConfirmModal = () =>
    this.setState({ confirmDialog: false, webhook_id: 0 });

  handleChange = (e) => {
    let webhook_info=this.state.webhook_info;
    webhook_info[e.target.name] = e.target.value;
    if (this.state.errors && this.state.errors.webhook) {
      this.setState({ errors: null, webhook_info: webhook_info,disable:false });
    } else {
      this.setState({  webhook_info: webhook_info,disable:false });
    }
  };

  handleSubmit = () => {
    const errors = this.validateForm();
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ disable:true});
      let { status, webhook_url, content_type, selectedWebhook,
        selectedValues, web_selectedWebhook,
        web_selectedValues} = this.state.webhook_info;

      // Construct filters array and convert to JSON string
      const filters = 
        selectedWebhook.map((key) => ({
          field: key,
          selectedValue: selectedValues[key] || null, // Attach selected values for each key
        }));
        // this.setState({loading: true});
        const web_filters = 
        web_selectedWebhook.map((key) => ({
          field: key,
          selectedValue: web_selectedValues[key] || null, // Attach selected values for each key
        }));

      // Construct payload
      const payload = {
        id:this.state.webhook_id,
        webhookStatus:status,
        webhook: webhook_url,
        content_type,
        filters, 
        web_filters,
        required_setting:this.state.webhook_info.required_setting,
        web_required_setting:this.state.webhook_info.web_required_setting,
        voice_on:this.state.webhook_info.voice_on==true || this.state.webhook_info.voice_on==="1" ? true : false,
        web_on:this.state.webhook_info.web_on==true || this.state.webhook_info.web_on==="1" ? true:false
      };


      // if (!status) webhook_url = "";
      api.webhook.createWebhook(payload).then((resp) => {
        if (resp && resp.message) {
          this.setState({ successMessage: resp.message,errorMessage:null,modal: false,loading:false,errors:null,webhhok_info:{},webhook_id:0,refreshGrid:true,disable:false }, () => {
             utilFunc.scrollTop(800);
            setTimeout(() => {
              this.setState({ successMessage: null,refreshGrid:false,disable:false });
            }, 5000);
          });
        }
      });
    }
  };

  validateForm() {
    const errors = {};
    // if (this.state.webhook_info.status) {
      if (!this.state.webhook_info.webhook_url) errors.webhook = "This field is required.";
      else if (!Validator.isURL(this.state.webhook_info.webhook_url)) {
        errors.webhook = "Invalid webhook url.";
      }
    // }
    setTimeout(() => {
      this.setState({ errors: null });
    }, 5000);
    return errors;
  }
  handleSwitch = (checked) => {let webhook=this.state.webhook_info ;
                               webhook.status = checked; 
                              this.setState({ webhook_info:webhook}); 
                            };

  handleSwitchvoice = (checked) => {let webhook=this.state.webhook_info ;
                              webhook.voice_on = checked; 
                             this.setState({ webhook_info:webhook}); 
                           };      
    handleSwitchweb = (checked) => {let webhook=this.state.webhook_info ;
    webhook.web_on = checked; 
    this.setState({ webhook_info:webhook}); 
  };                                               
  HandleRequiredSetting=(checked) => {let webhook=this.state.webhook_info ; webhook.required_setting=checked ? 1:0;this.setState({ webhook_info:webhook,disable:false});}
  HandleRequiredSettingforweb=(checked) => {let webhook=this.state.webhook_info ; webhook.web_required_setting=checked ? 1:0;this.setState({ webhook_info:webhook,disable:false});}
  // Handle dropdown change
  handleDropdownChange = (e, { value }) => {
    let webhookinfo=this.state.webhook_info;
    webhookinfo.content_type = value;
    this.setState({ webhook_info:webhookinfo,disable:false });
  };
  componentDidMount() {
    // Load webhook details
    this.getDataSource();
    // api.webhook.loadWebhook({}).then((resp) => {
    //   try {
    //     if (resp.webhook) {
    //       const webhookInfo = resp.webhook;

    //       // Load voice workspace branch details
    //       api.manageUser.getVoiceWorkspaceBranch({})
    //         .then((res) => {
    //           if (res.status) {
    //             this.setState({
    //               workspaceId: res.data.workspaceId,
    //               workspaceBranches: res.data.data,
    //               voice_type: res.data.type,
    //               parsed_values: res.data.parsed_values,
    //             }, () => {
    //               console.log(webhookInfo.filters, typeof webhookInfo.filters);
                  
    //               // Parse filters JSON string
    //               const savedFilters = webhookInfo.filters ? JSON.parse(webhookInfo.filters) : [];

    //               console.log('savedFilters', savedFilters, typeof savedFilters);
    //               const selectedWebhook = savedFilters.map((filter) => filter.field);
    //               const selectedValues = savedFilters.reduce((acc, filter) => {
    //                 acc[filter.field] = filter.selectedValue || null; // Default to null if no value
    //                 return acc;
    //               }, {});

    //               // Construct dynamicOptions based on parsed_values and savedFilters
    //               const dynamicOptions = {};
    //               selectedWebhook.forEach((field) => {
    //                 const parsedField = this.state.parsed_values?.find(
    //                   (item) => item.key === field
    //                 );
    //                 if (parsedField && Array.isArray(parsedField.val)) {
    //                   dynamicOptions[field] = parsedField.val.map((option, idx) => ({
    //                     key: idx,
    //                     text: option,
    //                     value: option.trim(),
    //                   }));
    //                 }
    //               });


    //               this.setState({
    //                 webhook: webhookInfo.webhook_url || "", // Default to empty string if null
    //                 webhookStatus: webhookInfo.status === "active", // Convert status to boolean
    //                 contentType: webhookInfo.content_type || "multipart/form-data", // Default to multipart/form-data
    //                 selectedWebhook,
    //                 selectedValues,
    //                 dynamicOptions, // Add dynamic options to state,
    //                 required_settings:webhookInfo.required_setting||0
    //               });
    //             });
    //           }
    //         })
    //         .catch((err) => {
    //           console.error("Error fetching workspace branch data:", err);
    //         });


    //     }
    //   } catch (e) {
    //     console.error("Error processing webhook response:", e);
    //   }
    // });


  }
  
  openPage() {
    window.open(
      "https://docs.google.com/document/d/1S0lzB7wsoTVpjsEhlnoAEIlPtY_XWnNer1zBmhcnax0/edit?usp=sharing"
    );
  }
  branchOption = () => {    
    let list = this.state.parsed_values.map((key, index) => {
      return {
        key: index,
        text: key.key,
        value: key.key,
      }
    });    
    return list;    
  }
  web_branchOption = () => {    
    let list = typeof this.state.web_parsed_values!=='undefined' ? this.state.web_parsed_values.map((key, index) => {
      return {
        key: index,
        text: key.key,
        value: key.key,
      }
    }):[];    
    return list;    
  }
  // Handle selection of main rules
  handleWebhookRule = (e, d) => {
    let webhookInfo = this.state.webhook_info;
    const selectedKeys = d.value;
    let dynamicOptions = {};

    // Loop through selected items and add dropdown options if `val` is non-empty
    selectedKeys.forEach((key) => {
      const field = this.state.parsed_values.find(item => item.key === key);
      if (field && Array.isArray(field.val) && field.val.length > 0) {
        dynamicOptions[key] = field.val.map((option, idx) => ({
          key: idx,
          text: option,
          value: option.trim()
        }));
      }
    });
    webhookInfo['selectedWebhook'] = selectedKeys;
    webhookInfo['dynamicOptions'] = dynamicOptions;
    // Update state with selected keys and dynamic options
    this.setState({
      webhook_info: webhookInfo,
      disable:false
    });
  };

  handleweb_WebhookRule = (e, d) => {
    let webhookInfo = this.state.webhook_info;
    const selectedKeys = d.value;
    let dynamicOptions = {};

    // Loop through selected items and add dropdown options if `val` is non-empty
    selectedKeys.forEach((key) => {
      const field = this.state.web_parsed_values.find(item => item.key === key);
      if (field && Array.isArray(field.val) && field.val.length > 0) {
        dynamicOptions[key] = field.val.map((option, idx) => ({
          key: idx,
          text: option,
          value: option.trim()
        }));
      }
    });
    webhookInfo['web_selectedWebhook'] = selectedKeys;
    webhookInfo['web_dynamicOptions'] = dynamicOptions;
    // Update state with selected keys and dynamic options
    this.setState({
      webhook_info: webhookInfo,
      disable:false
    });
  };

  // Render additional dropdowns dynamically based on selected items
  renderDynamicDropdowns = () => {
    return this.state.webhook_info.selectedWebhook.map((key) => {
      if (this.state.webhook_info.dynamicOptions[key]) {
        return (
          <div className="col-lg-12 col-md-12 col-sm-12 form-group-sub" key={key}>
            <label>{`Webhook will trigger if ${key} option equals`}</label>
            <Form.Field>
              <Dropdown
                placeholder={`Choose ${key} value`}
                fluid
                selection
                multiple
                options={this.state.webhook_info.dynamicOptions[key]}
                value={this.state.webhook_info.selectedValues[key] || []} // Set pre-selected values
                onChange={(e, d) => this.handleDynamicSelection(key, d.value)}
              />
            </Form.Field>
          </div>
        );
      }
      return null;
    });
  };
  web_renderDynamicDropdowns = () => {
    return this.state.webhook_info.web_selectedWebhook.map((key) => {
      if (this.state.webhook_info.web_dynamicOptions[key]) {
        return (
          <div className="col-lg-12 col-md-12 col-sm-12 form-group-sub" key={key}>
            <label>{`Webhook will trigger if ${key} option equals`}</label>
            <Form.Field>
              <Dropdown
                placeholder={`Choose ${key} value`}
                fluid
                selection
                multiple
                options={this.state.webhook_info.web_dynamicOptions[key]}
                value={this.state.webhook_info.web_selectedValues[key] || []} // Set pre-selected values
                onChange={(e, d) => this.web_handleDynamicSelection(key, d.value)}
              />
            </Form.Field>
          </div>
        );
      }
      return null;
    });
  };
    
  // Handle selection for each dynamic dropdown
  handleDynamicSelection = (key, value) => {
    let webhhok_info = this.state.webhook_info;
    let search = this.state.webhook_info.selectedValues;
    let sd={
      ...search,
      [key]: value, // Store selected values for each field
    }
    webhhok_info['selectedValues'] = sd;
    this.setState({ webhook_info: webhhok_info ,disable:false });
  };
  web_handleDynamicSelection = (key, value) => {
    let webhhok_info = this.state.webhook_info;
    let search = this.state.webhook_info.web_selectedValues;
    let sd={
      ...search,
      [key]: value, // Store selected values for each field
    }
    webhhok_info['web_selectedValues'] = sd;
    this.setState({ webhook_info: webhhok_info ,disable:false });
  };
  getDataSource = (params) => api.webhook.loadWebhook(params); 
   deleteCredentials = () => {
            this.setState({confirmDialog:false});
            api.webhook
                  .deleteWebhook({ id: this.state.webhook_id })
                  .then((resp) => {
                      console.log("result ---", resp);
                      if (resp && resp.message) {
                         
                          this.setState({ successMessage: resp.message,  loading: false,
                          webhook_id: 0, refreshGrid:true});
                         
                          utilFunc.scrollTop(800);
                          setTimeout(() => {
                              this.setState({ 
                                successMessage: null,
                                loading: false,
                               webhook_id: 0,refreshGrid:false });
                          }, 4000);
                      } else if (resp.errorMessage) {
                        this.setState({  errorMessage: resp.errorMessage,  loading: false,
                          webhook_id: 0, });
                         
                      }
                  })
                  .catch((err) => {
                      console.log(err);
                      this.setState({ 
                         loading: false,
                        webhook_id: 0, });
                  });
          
      };
  
      // edit act credentials
       editCredentials = (id) => {
          if (id) {
            api.webhook.getWebhookInfo({ id }).then((resp) => {
                  if (resp && resp.webhhok_info) {
                    api.manageUser.getVoiceWorkspaceBranch({})
            .then((res) => {
              if (res.status) {
            
                this.setState({
                  workspaceId: res.data.workspaceId,
                  workspaceBranches: res.data.data,
                  voice_type: res.data?.type,
                  parsed_values: res.data?.parsed_values,
                  web_workspaceId: res.data?.webparsedetail?.workspaceId,
                  web_workspaceBranches: res.data?.webparsedetail?.data,
                  web_parsed_values: res.data?.webparsedetail?.parsed_values,
                }, () => {
                
                  
                  // Parse filters JSON string for voice
                  const savedFilters =resp.webhhok_info.filters ? JSON.parse(resp.webhhok_info.filters) : [];
                  const selectedWebhook = savedFilters.map((filter) => filter.field);
                  const selectedValues = savedFilters.reduce((acc, filter) => {
                    acc[filter.field] = filter.selectedValue || null; // Default to null if no value
                    return acc;
                  }, {});

                  // Construct dynamicOptions based on parsed_values and savedFilters
                  const dynamicOptions = {};
                  selectedWebhook.forEach((field) => {
                    const parsedField = this.state.parsed_values?.find(
                      (item) => item.key === field
                    );
                    if (parsedField && Array.isArray(parsedField.val)) {
                      dynamicOptions[field] = parsedField.val.map((option, idx) => ({
                        key: idx,
                        text: option,
                        value: option.trim(),
                      }));
                    }
                  });
                  // Parse filters JSON string for web
                 
                  const web_savedFilters =resp.webhhok_info.web_filters ? JSON.parse(resp.webhhok_info.web_filters) : [];
                  const web_selectedWebhook = web_savedFilters.map((filter) => filter.field);
                 
                  const web_selectedValues = web_savedFilters.reduce((acc, filter) => {
                    acc[filter.field] = filter.selectedValue || null; // Default to null if no value
                    return acc;
                  }, {});

                  // Construct dynamicOptions based on parsed_values and savedFilters
                  const web_dynamicOptions = {};
                  web_selectedWebhook.forEach((field) => {
                    const webparsedField = this.state.web_parsed_values?.find(
                      (item) => item.key === field
                    );
                    if (webparsedField && Array.isArray(webparsedField.val)) {
                      web_dynamicOptions[field] = webparsedField.val.map((option, idx) => ({
                        key: idx,
                        text: option,
                        value: option.trim(),
                      }));
                    }
                  });


                  let webhhok_info=resp?.webhhok_info ?? {};
                  webhhok_info['selectedWebhook'] = selectedWebhook;
                  webhhok_info['dynamicOptions'] = dynamicOptions;
                  webhhok_info['selectedValues'] = selectedValues;
                  webhhok_info['web_selectedWebhook'] = web_selectedWebhook;
                  webhhok_info['web_dynamicOptions'] = web_dynamicOptions;
                  webhhok_info['web_selectedValues'] = web_selectedValues;
                  webhhok_info['web_on']=resp.webhhok_info.web_on;
                  webhhok_info['voice_on']=resp.webhhok_info.voice_on;
                  
                  console.log( webhhok_info,"webhook_info dd")
                  this.setState({webhook_info:webhhok_info,webhook_id:id,modal:true});
                     this.createNew(false);
                });
              }
            })
            .catch((err) => {
              console.error("Error fetching workspace branch data:", err);
            });
                  
                  }
              });
          }
      };

      createNew = (old) => {
        if(!old){  
          this.setState({
            modal:true
          });
        }else{
          api.manageUser.getVoiceWorkspaceBranch({})
          .then((res) => {
            if (res.status) {
              this.setState({
                workspaceId: res.data.workspaceId,
                  workspaceBranches: res.data.data,
                  voice_type: res.data?.type,
                  parsed_values: res.data?.parsed_values,
                  web_workspaceId: res.data?.webparsedetail?.workspaceId,
                  web_workspaceBranches: res.data?.webparsedetail?.data,
                  web_parsed_values: res.data?.webparsedetail?.parsed_values,
              }, () => {
                const savedFilters = [];
                const selectedWebhook = savedFilters.map((filter) => filter.field);
                const selectedValues = savedFilters.reduce((acc, filter) => {
                  acc[filter.field] = filter.selectedValue || null; // Default to null if no value
                  return acc;
                }, {});

                // Construct dynamicOptions based on parsed_values and savedFilters
                const dynamicOptions = {};
                selectedWebhook.forEach((field) => {
                  const parsedField = this.state.parsed_values?.find(
                    (item) => item.key === field
                  );
                  if (parsedField && Array.isArray(parsedField.val)) {
                    dynamicOptions[field] = parsedField.val.map((option, idx) => ({
                      key: idx,
                      text: option,
                      value: option.trim(),
                    }));
                  }
                });

                //for web

                const web_savedFilters = [];
                const web_selectedWebhook = savedFilters.map((filter) => filter.field);
                const web_selectedValues = savedFilters.reduce((acc, filter) => {
                  acc[filter.field] = filter.selectedValue || null; // Default to null if no value
                  return acc;
                }, {});

                // Construct dynamicOptions based on parsed_values and savedFilters
                const web_dynamicOptions = {};
                web_selectedWebhook.forEach((field) => {
                  const parsedField = this.state.web_parsed_values?.find(
                    (item) => item.key === field
                  );
                  if (parsedField && Array.isArray(parsedField.val)) {
                    web_dynamicOptions[field] = parsedField.val.map((option, idx) => ({
                      key: idx,
                      text: option,
                      value: option.trim(),
                    }));
                  }
                });
                let webhhok_info= {status:true,webhook_url:"",required_settings:0,contentType:"multipart/form-data"};
                webhhok_info['selectedWebhook'] = selectedWebhook;
                webhhok_info['dynamicOptions'] = dynamicOptions;
                webhhok_info['selectedValues'] = selectedValues;
                webhhok_info['web_selectedWebhook'] = web_selectedWebhook;
                webhhok_info['web_dynamicOptions'] = web_dynamicOptions;
                webhhok_info['web_selectedValues'] = web_selectedValues;
                this.setState({webhook_info:webhhok_info,webhook_id:0,modal:true});
              });
            }
          })
          .catch((err) => {
            console.error("Error fetching workspace branch data:", err);
          });
       
        }
        
  
      };
  
    clearState = (data = null) => {
      return new Promise((resolve, reject) => {
        let elem = {
          modal: false,
          webhook_id:0,
          webhhok_info:{},
          errorMessage:null,
          successMessage:null,
          errors:null,
        };
  
        if (data) {
          elem = { ...elem, ...data };
        }
        this.setState(elem, () => {
          resolve();
        });
      });
    };
    toggleModal = () => this.clearState();

  submitForm = () => this.formRef.current.submit();


    createCredentials = () => {
      const errorLabel = <div className="red" pointing={"true"} />;
    const options = [
      { key: 'json', text: 'JSON', value: 'application/json' },
      { key: 'form', text: 'Form Data', value: 'multipart/form-data' },
    ];
      return (
        <div
          className={this.state.modal ? "modal fade show " : "modal fade"}
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdrop"
          aria-hidden="true"
          style={{ display: this.state.modal ? "block" : "none" }}
        >
          {this.state.formLoading && <div className="ui loading form"></div>}
          {this.state.successMessage && <div className="ui success message">{this.state.successMessage}</div>}
          
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close linear-gradient"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.toggleModal} 
              >
                X
              </button>
              <div className="modal-header">
                <h5 className="modal-title pl-0">{"Webhook"}</h5>
              </div>
              <div className="modal-body webhookform">
              <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub d-flex flex-row pl-0">
                  <label class="form-control-label enable-label pl-0">Enable Webhook</label>
                  <div style={{ margin: "-7px 175px 0 10px"}}>
                    <Switch
                      onChange={this.handleSwitch}
                      checked={this.state.webhook_info.status==true || this.state.webhook_info.status=='active' ? true: false}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                      id="material-switch"
                    />
                    
                  </div> 
                 
                  </div>
                  {(this.state.errors && (this.state.webhook_info.status==false || this.state.webhook_info.status=='inactive')) && (
                        <div className="red" pointing={"true"}>
                          <p className="text-danger">Enable and enter a webhook URL to save.</p>
                        </div>
                      )}
                 <Form  
                  noValidate
                  autoComplete="off"
                  onValidSubmit={this.handleSubmit}
                  className="kt-form kt-form--label-right"
                  ref={this.formRef}
                  style={{ fontFamily:'Poppins", sans-serif' }}
                  id="webhookinfo-form"
                >
                 {(this.state.webhook_info.status==true || this.state.webhook_info.status=='active') && <React.Fragment>                 
                   <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub webform mb-3 pl-0">
                      <label class="form-control-label">
                        Webhook Url <span>*</span>
                      </label>
                      <Form.Input
                        type="text"
                        autoComplete="off"
                        name="webhook_url"
                        placeholder="Webhook Url"
                        className="webform"
                        value={this.state.webhook_info.webhook_url}
                        onChange={this.handleChange}
                        style={{padding:"1 !important",borderBottom:"1px solid hsl(227, 6%, 68%) !important"}}
                        error={
                          this.state.errors && this.state.errors.webhook
                            ? true
                            : false
                        }
                      />
                      {this.state.errors && (
                        <div className="red" pointing={"true"}>
                          {this.state.errors.webhook}
                        </div>
                      )}
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mb-3 pl-0 content-type-select">
                    <Form.Field>
                      <label style={{textAlign:'left'}}>Content Type</label>
                      <Dropdown
                        placeholder="Select Content Type"
                        fluid
                        selection
                        options={options}
                        onChange={this.handleDropdownChange}
                        value={this.state.webhook_info.content_type}
                      />
                    </Form.Field>
                     </div>
                 <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mb-3 pl-0">
                  <label class="form-control-label enable-label">Enable Voice</label>
                  <div style={{ margin: "-29px 175px 0 145px"}}>
                    <Switch
                      onChange={this.handleSwitchvoice}
                      checked={this.state.webhook_info.voice_on==true || this.state.webhook_info.voice_on=='on' ? true: false}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                      id="material-switch"
                    />
                    
                  </div> 
                  </div>
                {(this.state.webhook_info.voice_on=='1' || this.state.webhook_info.voice_on==true) && <fieldset style={{border:'solid 1px',marginBottom:'10px'}}>
                   
                    {this.state.webhook_info.voice_on && this.state.parsed_values &&
                      <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mb-3 pl-0">
                        <label>Add webhook filtering rule</label>
                        <Form.Field>                          
                          <Dropdown
                            placeholder="Select Rule"
                            fluid
                            multiple
                            search
                            selection
                            options={this.branchOption()}
                            onChange={this.handleWebhookRule}
                            value={this.state.webhook_info.selectedWebhook}
                          />
                        </Form.Field>
                      </div>
                    }                    
                  {this.renderDynamicDropdowns()}
                  <div className="form-group row">
                 <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub d-flex flex-row">
                  <Checkbox
                  label={`Use the "If all required fields are gathered" rule within the workspace to determine when to pass a payload`}
                        onChange={(e, data)=>this.HandleRequiredSetting(data.checked)}
                        checked={this.state.webhook_info.required_setting==1 || this.state.webhook_info.required_setting==true ? true: false}
                      />
                
                 </div>
                 </div></fieldset>}
                 <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mb-3 pl-0">
                 <label class="form-control-label enable-label">Enable Chat</label>
                  <div style={{ margin: "-29px 175px 0 145px"}}>
                    <Switch
                      onChange={this.handleSwitchweb}
                      checked={this.state.webhook_info.web_on==true || this.state.webhook_info.web_on=='on' ? true: false}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                      id="material-switch"
                    />
                    
                  </div>    
                  </div>
                  {(this.state.webhook_info.web_on=='1' || this.state.webhook_info.web_on==true) && <fieldset style={{border:'solid 1px'}}>
                    {this.state.web_parsed_values &&
                      <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mb-3 pl-0">
                        <label>Add webhook filtering rule</label>
                        <Form.Field>                          
                          <Dropdown
                            placeholder="Select Rule"
                            fluid
                            multiple
                            search
                            selection
                            options={this.web_branchOption()}
                            onChange={this.handleweb_WebhookRule}
                            value={this.state.webhook_info.web_selectedWebhook}
                          />
                        </Form.Field>
                      </div>
                    }                    
                  {this.web_renderDynamicDropdowns()}
                  <div className="form-group row">
                 <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub d-flex flex-row">
                  <Checkbox
                  label={`Use the "If all required fields are gathered" rule within the workspace to determine when to pass a payload`}
                        onChange={(e, data)=>this.HandleRequiredSettingforweb(data.checked)}
                        checked={this.state.webhook_info.web_required_setting==1 || this.state.webhook_info.web_required_setting==true ? true: false}
                      />
                
                 </div>
                 </div></fieldset>}

                  </div>
                
               
               
                 </React.Fragment> }
                <div className="form-group row" style={{marginTop:'10px'}}>
                  <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub ">
                    <button
                      type="submit"
                      disabled={this.state.disable}
                      style={{ float: "right" }}
                      className="btn linear-gradient yr-submitbtn btn-sm"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
              </div>
             
            </div>
          </div>
        </div>
      );
    };

  render() {
    
    return (
      <React.Fragment>
        <div className="row" id="webhookportal">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {this.state.modal && this.createCredentials()}
            {this.state.confirmDialog && (
              <ConfirmDialog
                show={this.state.confirmDialog}
                toggleModal={this.deleteCredentials}
                closeAction={this.toggleConfirmModal}
                title="Confirm your action"
                message={"Do you really want to delete this credential ?"}
              />
            )}         
                       {this.state.loading && <div className="ui loading form"></div>}
            <div className="kt-portlet__body kt-portlet__body--fit ">
            {this.state.successMessage && (
                         <Message color={"green"}>{this.state.successMessage}</Message>
                       )}
                       {this.state.errorMessage && (
                         <Message color={"red"}>{this.state.errorMessage}</Message>
                       )}
              <div class="form-group row">
                <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub ">
                  <button
                    type="submit"
                    style={{ float: "center" }}
                    className="btn linear-gradient yr-submitbtn btn-sm"
                    onClick={this.openPage}
                  >
                    Webhook Tutorial Docs
                  </button>
                
                </div>
              </div>
              <div className="yr-manageUser-table">
                        <Segment>
                            <div className="webhook-integration act--integration">
                                <GridList
                                    dataSource={(params) => this.getDataSource(params)}
                                    dataColumns={this.columnLists}
                                    refreshGrid={this.state.refreshGrid}
                                >
                                    <div className="col-md-8 kt-margin-b-20-tablet-and-mobile">
                                        <button
                                            style={{ marging: "0 0 15px 0", float: "right" }}
                                            type="button"
                                            className="btn linear-gradient yr-submitbtn"
                                            onClick={()=>this.createNew(true)}
                                        >
                                            Create New
                                        </button>
                                    </div>
                                </GridList>
                            </div>
                        </Segment>
                    </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Webhook;
