import React, { useState, useEffect, useContext } from 'react';
import { Table, Input, Pagination } from 'semantic-ui-react';
import styles from "./DataConnections.module.css";
import dataconnection from '../../../api/DataConnection/dataconnection';
import { DataContext } from './DataProvider';
import DataLayerModal from './DataLayerModal';
import './CustomModal.css'; // Import custom styles for the modal
import ConfirmationModal from './ConfirmationModal'; // Import the styled confirmation modal

const DatabaseListing = (props) => {
  const { connections, updateConeection } = useContext(DataContext);
  const [conectlist, setConectlist] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');  // For search input
  const [currentPage, setCurrentPage] = useState(1);    // For pagination
  const itemsPerPage = 5;

  useEffect(() => {
    if (connections != undefined) {
      setConectlist(connections);
      props.setLoading(false);
    }
  }, [connections]);

  // Filtered list based on the search query
  const filteredList = conectlist.filter(database =>
    database.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  // Open confirmation modal
  const openModal = (database) => {
    setSelectedDatabase(database);
    setModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setSelectedDatabase(null);
    setModalOpen(false);
  };

  // Confirm delete action
  const confirmDelete = async () => {
    if (selectedDatabase) {
      try {
        props.setLoading(true);
        // Call API to delete the database
        dataconnection.deleteConnection(selectedDatabase.id).then((res) => {
          setConectlist(res.data);
          updateConeection(res.data);
          props.setDatabases(res.data);
          props.setLoading(false);
        });
      } catch (error) {
        console.error("Failed to delete the database:", error);
      } finally {
        closeModal();
      }
    }
  };

  // Open modal to update database
  const updateDBConeection = (database) => {
    props.setinitFormdata(database);
    props.onOpen();
  };

  const changejsontostring = (data) => {
    const parsedData = JSON.parse(data);
    const formattedString = parsedData
      .map((item) => `Name:${item.name}, Type:${item.type}`)
      .join(' | ');
    return formattedString;
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);  // Reset to page 1 when search query changes
  };

  // Handle page change
  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  return (
    <>
      <div >
        <Input
          icon="search"
          placeholder="Search by database name"
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ marginBottom: '20px',minWidth:"300px", marginTop: '30px' }}
        />
      </div>

      {
        props.loading === true ? <p>Loading ...</p> :
        (conectlist !== undefined && conectlist.length) ?
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Columns</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {currentItems.map((database) => (
              <Table.Row key={database.id ?? ""}>
                <td className={`${styles.datalaytd}`}>{database.name}</td>
                <td className={`${styles.datalaytd}`}>{changejsontostring(database.columns) ?? ""}</td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table> :
        <p>No database available. Please add a new database.</p>
      }

      {/* Pagination */}
      <Pagination
        activePage={currentPage}
        totalPages={Math.ceil(filteredList.length / itemsPerPage)}
        onPageChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />

      {/* Custom Confirmation Modal */}
      <ConfirmationModal
        open={modalOpen}
        onClose={closeModal}
        onConfirm={confirmDelete}
      />

      {/* Edit Modal */}
      {props.modalStatus && (
        <DataLayerModal
          open={props.modalStatus}
          onClose={props.onClose}
          onOpen={props.onOpen}
          setDatabases={props.setDatabases}
          initFormdata={props.initFormdata}
        />
      )}
    </>
  );
};

export default DatabaseListing;
