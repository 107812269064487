import React, { useContext, useState,useEffect,useMemo } from 'react';
import { Table, Button, Icon, Modal, Input } from 'semantic-ui-react';
import styles from "./DataConnections.module.css";
import ConfirmationModal from './ConfirmationModal'; // Import the styled confirmation modal
import dataconnection from '../../../api/DataConnection/dataconnection';

const ConnectionList = ({ onEdit, toolapiendpoint ,loadToolApiendpoint,conloaderSatus,setConloaderStatus,resetCOnnectform }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const [selectedDb, setSelectedDb] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    
    // Sort data in descending order by created_at
    const sortedData = useMemo(() => {
        if(toolapiendpoint){
            return [...toolapiendpoint].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }
    }, [toolapiendpoint]);


    
    // Pagination logic
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = toolapiendpoint ? sortedData.slice(indexOfFirstRecord, indexOfLastRecord) : [];
    const totalPages = toolapiendpoint ? Math.ceil(sortedData.length / recordsPerPage) : [];

    const confirmDelete = () => {
        if (selectedDb) {
            setConloaderStatus(true)
            dataconnection.deleteDataApiConnection(selectedDb.id).then(() => {
                loadToolApiendpoint();
                closeModal();
            });
            resetCOnnectform(); 
        }
    };

    useEffect(() => {
        if (toolapiendpoint != undefined) {
            setConloaderStatus(false);
        }
    }, [toolapiendpoint]);

    const openModal = (db) => {
        setSelectedDb(db);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedDb(null);
    };
    return (
        <>
        {
            (toolapiendpoint !== undefined && conloaderSatus == false) ?
            <div style={{ marginTop: "20px" }}>
                {toolapiendpoint.length === 0 ? (
                    <p>No connections available. Please add a new database.</p>
                ) : (
                    <div className={`${styles.datalayerTable}`}>
                       
                        <div> 

                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                        <Table.HeaderCell>My Endpoint</Table.HeaderCell>
                                        <Table.HeaderCell>Updated Interval</Table.HeaderCell>
                                        <Table.HeaderCell>Add or Replace?</Table.HeaderCell>
                                        <Table.HeaderCell>Action</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {currentRecords.map((db) => (
                                        <Table.Row key={db.id}>
                                            <Table.Cell>{db.endpoint_name}</Table.Cell>
                                            <Table.Cell ><span className={styles.truncate}> {db.endpoint_url}</span></Table.Cell>
                                            <Table.Cell>{db.timeInSWC}</Table.Cell>
                                            <Table.Cell>{db.operation}</Table.Cell>
                                            <Table.Cell>
                                                <span title="Edit Connection" onClick={() => onEdit(db)} style={{ cursor: "pointer" }}>
                                                    <i className="la la-edit blue"></i>
                                                </span>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                            {/* Pagination Controls */}
                            <div className="pagination-controls">
                                <span>Page {currentPage} of {totalPages}</span>
                                <Button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
                                    Previous
                                </Button>
                                
                                <Button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Custom Confirmation Modal */}
                
                    <ConfirmationModal
                        open={modalOpen}
                        onClose={closeModal}
                        onConfirm={confirmDelete}
                        
                    />
            </div> :  <p>Loading...</p>
        }
        </>
    );
};

export default ConnectionList;
