import React from "react";
//import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

export class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
  }
  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (value === "") {
      this.setState({ [name]: value }, () => {
        this.props.search(this.props.search(this.state.search));
      });
    }
  };
  onSearchSubmit = (event) => {
    event.preventDefault();
    if (this.state.search && this.state.search !== "")
      this.props.search(this.props.search(this.state.search));
  };
  render() {
    return (
      <div className="kt-form kt-form--label-right kt-margin-t-10 kt-margin-b-10">
        <div className="row align-items-center">
          <div className="col-xl-12 order-2 order-xl-1">
            <div className="row align-items-center">
              <div className="col-md-4 kt-margin-b-20-tablet-and-mobile">
                <div className="kt-input-icon kt-input-icon--left">
                  <Form onSubmit={this.onSearchSubmit}>
                    <Form.Input
                      style={{ marginLeft: '4' }}
                      name="search"
                      id="customPadRight"
                      value={this.state.search}
                      onChange={this.handleInput}
                      loading={this.props.isLoading}
                      autoComplete="off"
                    />
                  </Form>
                  <span
                    className="kt-input-icon__icon kt-input-icon__icon--left"
                    style={{ cursor: "pointer" }}
                    onClick={this.onSearchSubmit}
                  >
                    <span>
                      <i className="la la-search" />
                    </span>
                  </span>
                  <span className="yr-searchPlace-holder">Search</span>
                </div>
              </div>
              {/* <div className="col-md-8 kt-margin-b-20-tablet-and-mobile"> */}
              {this.props.otherProps}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// DatatableFilter.propTypes = {
// 	onSubmitFilter: PropTypes.func.isRequired,
// 	filter: PropTypes.string.isRequired,
// 	totalCount: PropTypes.number.isRequired,
// };
