import React, { useState, useEffect, useRef } from "react";
import api from '../../../../../../../api';
import {
	Form,
	Button,
	Checkbox,
	Table,
	Input,
	TextArea,
	Dropdown,
	Header,
	Modal,
	Icon,
	Dimmer,
	Loader,
	Message
} from "semantic-ui-react";
import styles from "./DataConnect.module.css";
import "./DataConnectPage.css";

const DataLayerModal = ({ open, onClose, preformData, updateConnectiontools, setshowErrortype, connections, workspace_id }) => {
	const [formData, setFormData] = useState(preformData);
	const [loading, setLoading] = useState(true);
	const [editId, seteditId] = useState(null);
	const [subitStatus, setSubmitStatus] = useState(false);
	const [showErrortypeModal, setshowErrortypeModal] = useState({ type: "", title: "", message: "" });
	const [newProperty, setNewProperty] = useState({
		name: "",
		description: "",
		type: "string",
	});
	const modalTopRef = useRef(null);

	useEffect(() => {
		if (showErrortypeModal.message) {
			setTimeout(() => {
				setshowErrortypeModal({ type: "", title: "", message: "" });
			}, 7000);
			if (modalTopRef.current) {
				// modalTopRef.current.scrollIntoView({ behavior: "smooth" });
				modalTopRef.current.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [showErrortypeModal]);

	const handleInputChange = (e, data = null) => {
		const { name, value, type, checked } = e.target;
		if(name == 'connectionName'){
			//check this value should not contains any special charecter expect _
			if(!/^[a-zA-Z0-9_]+$/.test(value)){

				setshowErrortypeModal({ type: "negative", title: "Failed!", message: "Connection Name should not contains any special charecter expect _" });
				return;
			}

		}
		if (name !== undefined) {
			setFormData((prev) => ({
				...prev,
				[name]: value,
			}));
		} else {
			setFormData((prev) => ({
				...prev,
				[data.name]: data.checked,
			}));
		}
	};

	const handlePropertyChange = (key, value) => {
		setNewProperty((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const handleAddProperty = () => {
		if (editId !== null) {
			if (newProperty.name != undefined && newProperty.description != undefined && newProperty.name.trim()) {
				if (newProperty.name.trim() !== '' && newProperty.description.trim() !== "") {
					formData.properties[editId] = newProperty;
					setFormData((prev) => ({
						...prev,
						properties: [...prev.properties],
					}));
					setNewProperty({ name: "", description: "", type: "string" });
					seteditId(null);
				} else {
					setshowErrortypeModal({ type: "negative", title: "Failed!", message: "Name or Description should not be blank." });
				}
			} else {
				setshowErrortypeModal({ type: "negative", title: "Failed!", message: "Name or Description should not be blank." });
			}
			return;
		} else {
			seteditId(null);
			if (newProperty.name != undefined && newProperty.description != undefined && newProperty.name.trim()) {
				if (newProperty.name.trim() !== '' && newProperty.description.trim() !== "") {
					setFormData((prev) => ({
						...prev,
						properties: [...prev.properties, newProperty],
					}));
					setNewProperty({ name: "", description: "", type: "string" });
				} else {
					setshowErrortypeModal({ type: "negative", title: "Failed!", message: "Name and description should not be empty." });
				}
			} else {
				setshowErrortypeModal({ type: "negative", title: "Failed!", message: "Name or Description should not be blank." });
			}
		}
	};

	const handleDeleteProperty = (id) => {
		// remove the property from the list who has index id
		formData.properties.splice(id, 1);
	};

	const handleSubmit = async () => {
		// Validate required fields
		setLoading(true);
		setSubmitStatus(true);
		const { connectionName, description, properties } = formData;
		if (!connectionName.trim()) {
			setshowErrortype({ type: "negative", title: "Failed!", message: "Connection Name is required!" });
			setLoading(false);
			setSubmitStatus(true);
			onClose();
			return;
		}
		if (!description.trim()) {
			setshowErrortype({ type: "negative", title: "Failed!", message: "Description is required!" });
			setLoading(false);
			setSubmitStatus(true);
			onClose();
			return;
		}
		if (properties.length === 0) {
			setshowErrortype({ type: "negative", title: "Failed!", message: "Properties are required!" });
			setLoading(false);
			setSubmitStatus(true);
			onClose();
			return;
		}
		try {
			if (formData.id == null) {
				let payload = { ...formData, workspace_id: atob(workspace_id) }
				api.DatalayerandTooling.saveNewturboTool(payload).then((res) => {
					if (res.status == true) {
						updateConnectiontools(workspace_id);
						setshowErrortype({ type: "positive", title: "Succes!", message: "New tool has been added succesfully." });
						setLoading(false);
						setSubmitStatus(true);
					} else {
						setshowErrortype({ type: "negative", title: "Failed!", message: "Something went wrong." });
						setLoading(false);
						setSubmitStatus(true);
					}
					onClose();
				})
			} else {
				let payload = { ...formData, workspace_id: workspace_id }
				api.DatalayerandTooling.editNewturboTool(payload).then((res) => {
					if (res.status != 200) {
						updateConnectiontools(workspace_id);
						setshowErrortype({ type: "positive", title: "Succes!", message: "Toll has been updated succesfully." });
						setLoading(false);
						setSubmitStatus(true);
					} else {
						setshowErrortype({ type: "negative", title: "Failed!", message: "Something went wrong." });
						setLoading(false);
						setSubmitStatus(true);
					}
					onClose();
				})
			}
		} catch (error) {
			setshowErrortype({ type: "negative", title: "Failed!", message: error.message });
			setLoading(false);
			setSubmitStatus(true);
			onClose();
		}
	};

	// this function will display alert for succes and failure of updation and saving tool
	const messageAlert = () => {
		if (showErrortypeModal.title != "") {
			return (
				showErrortypeModal.title == 'negative' ?
					<Message negative>
						<Message.Header>{showErrortypeModal.title}</Message.Header>
						<p>{showErrortypeModal.message}</p>
					</Message> :
					<Message positive>
						<Message.Header>{showErrortypeModal.title}</Message.Header>
						<p>{showErrortypeModal.message}</p>
					</Message>
			)
		} else {
			return <></>
		}

	}
	// function which can edit the property according to their index
	const handleEditProperty = (id) => {
		let property = formData.properties[id];
		setNewProperty(property);
		//set action type to edit
		seteditId(id);
	}
	//set selected selection
	const setSelectedProperties = (value) => {
		setFormData((prev) => ({
			...prev,
			required: value,
		}));
	}

	//Render selectedproperties
	const renderSelectedProperties = () => {
		// Define your options for the dropdown
		const propertyOptions = formData.properties.map(property => ({
			key: property.name,
			text: property.name,
			value: property.name
		}));

		return (
			<>
				{/* Your existing code */}
				<Form.Field className={styles.mrtop}>
					<label>Select Properties</label>
					<Dropdown
						placeholder="Select properties"
						fluid
						multiple
						selection
						options={propertyOptions}
						value={formData.required}
						onChange={(e, { value }) => setSelectedProperties(value)}
					/>
				</Form.Field>
				{/* Your existing code */}
			</>
		);
	}
const svdb=(value)=>{
	console.log(value,"svdb");
	setFormData((prev) => ({ ...prev, databasefortool: value }))
}
	const renderSelectDatabase = () => {
		const databaseconections = connections.map((db) => {
			return {
				key: db.id,
				text: db.name,
				value: db.id,
			};
		});
		return (
			<Form.Field className={styles.mrtop}>
				<label className={styles.mrtop}>Select Database</label>
				<Dropdown
					placeholder="Select database"
					fluid
					selection
					options={databaseconections}
					value={formData.databasefortool}
					onChange={(e, { value }) => svdb(value)}
				/>
			</Form.Field>
		);
	}
	return (
		<>
			<div ref={modalTopRef}></div>
			<Dimmer
				className={styles.newLoader}
				active={loading}
				inverted
			>
			</Dimmer>
			<Modal open={open} onClose={onClose} className={styles.customModal}>
				<Loader >Loading...</Loader>
				<Modal.Header className={styles.modalHeader} >
					<span>Turbo Voice Tools</span>
					<button type="button" class="close linear-gradient" onClick={onClose}
						data-dismiss="modal" aria-label="Close"
						style={{
							fontSize: '16px',
							padding: "2px",
							width: "30px",
							height: "30px"
						}}>X</button>
				</Modal.Header>
				<Modal.Content>

					<Form id="dataLayerForm" >
						<Form.Field className={styles.mrtop}>
							<label>Connection Name</label>
							<span className="small" style={{ color: 'orange' }}>Note: Connection Name cannot contain whitespace or special characters except '_'</span>
							{messageAlert()}
							
							<Input
								name="connectionName"
								value={formData.connectionName}
								onChange={handleInputChange}
								style={{ backgroundColor: "#f9f9f9" }}
								className={styles.inputBorderFeild}
							/>
						</Form.Field>
						<Form.Field>
							<label>What does this connection do?</label>
							<TextArea
								name="description"
								placeholder="The purpose of the tool is to look up info about an existing customer"
								value={formData.description}
								onChange={handleInputChange}
								style={{ minHeight: 80 }}
							/>
						</Form.Field>
						<Form.Field>
							<Checkbox
								label="Async"
								name="isAsync"
								checked={formData.isAsync}
								onChange={(e, data) => handleInputChange(e, data)}
							/>
							<p>This setting defines whether the assistant will move forward or wait for your server to respond. Enable this if you just want to trigger something on your server.</p>
						</Form.Field>
					</Form>

					<Header as="h4">Properties</Header>
					<p>All properties must be trigger your connection.</p>
					{messageAlert()}
					<Form.Field style={{ width: '100%' }}>
						<label>Property Name </label>
						<Input
							className={`${styles.inputBorderFeild}`}
							placeholder="Enter property name"
							value={newProperty.name ?? ""}
							onChange={(e) => handlePropertyChange("name", e.target.value)}
						/>
					</Form.Field>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Describe the property</label>
							<TextArea
								placeholder="Enter description"
								value={newProperty.description}
								onChange={(e) => handlePropertyChange("description", e.target.value)}
								style={{ minHeight: 80 }}
							/>
						</Form.Field>

						<Form.Field>
							<label>Property Type</label>
							<Dropdown
								placeholder="Select property type"
								fluid
								selection
								options={[
									{ key: "string", text: "String", value: "string" },
									{ key: "number", text: "Number", value: "number" },
									{ key: "boolean", text: "Boolean", value: "boolean" },
									{ key: "object", text: "Object", value: "object" },
									{ key: "array", text: "Array", value: "array" },
								]}
								value={newProperty.type}
								onChange={(e, { value }) => handlePropertyChange("type", value)}
							/>
						</Form.Field>


						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							{
								editId == null ?
									<button
										type="button"
										className="btn btn-icon"
										onClick={handleAddProperty}
									>
										Add
									</button> :
									<button
										type="button"
										className="btn btn-icon delete-btn"
										onClick={handleAddProperty}
									>
										Edit
									</button>
							}

						</div>
					</Form.Group>
					<Header as="h4">Properties for this connection:</Header>
					<Table celled compact>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Property Name</Table.HeaderCell>
								<Table.HeaderCell>Actions</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{formData.properties.map((prop, index) => (
								<Table.Row key={index}>
									<Table.Cell>{prop.name}</Table.Cell>
									<Table.Cell>
										<div style={{ display: "flex", gap: "10px" }}>

											<button
												type="button"
												className="btn btn-icon"
												onClick={() => handleEditProperty(index)}
											>
												<i className="la la-edit blue" title="Edit"></i>
											</button>

											<button
												type="button"
												className="btn btn-icon"
												onClick={() => handleDeleteProperty(index)}
											>
												<i className="la la-trash red" title="Delete"></i>
											</button>
										</div>
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
					{renderSelectedProperties()}
					{renderSelectDatabase()}
					{/* {formData.id == null && (
						<Form.Field className={styles.mrtop}>
							<label>Server URL</label>
							<span className="small" style={{ color: 'orange' }}>Note: Once the server URL is added, it cannot be changed.</span>
							<Input
								name="serverUrl"
								placeholder="Enter server URL"
								value={formData.serverUrl}
								onChange={handleInputChange}
								style={{ backgroundColor: "#f9f9f9" }}
								className={styles.inputBorderFeild}
							/>
						</Form.Field>
					)} */}
				</Modal.Content>
				{
					!subitStatus && 
						<Modal.Actions>
							<button onClick={handleSubmit} className="btn linear-gradient yr-submitbtn">
								Save
							</button>
						</Modal.Actions>
				}
				</Modal>
		</>
	);
};

export default DataLayerModal;
