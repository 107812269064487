import React, { useState, useContext } from "react";
import { DataProvider } from '../../../../../../../components/Integration/DataConnections/DataProvider';
import DataConnectPage from './DataConnectPage'

const DataConnect = (props) => {
  return (
	<DataProvider>
		<DataConnectPage 
		workspace_id={props.workspace_id}
		/>
	</DataProvider>
  );
};

export default DataConnect;
