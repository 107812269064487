import React, { useState } from 'react'

const VoceraResultEmbed = ({ accessToken, agentId }) => {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <div className='row' style={{ height: '100%', margin: 0 }}>
            <div className='col-md-12' style={{ height: '100%', padding: 0 }}>
                <div className='card' style={{ height: '100%', margin: 0 }}>
                    <div className='card-body' style={{ height: '100%', padding: 10 }}>
                        <div className='row' style={{ height: '100%', margin: 0 }}>
                            {accessToken && agentId && (
                                <>
                                    {isLoading && (
                                        <div style={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: '#f8f9fa',
                                            zIndex: 1
                                        }}>
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    )}
                                    <iframe
                                        src={`https://dashboard.vocera.ai/embed/${agentId}/result/all?token=${accessToken}`}                                
                                        allowFullScreen
                                        loading="lazy"
                                        title="Vocera Result Embed"
                                        width="100%"
                                        style={{ 
                                            border: 'none',
                                            height: '200vh',
                                            width: '100%'
                                        }}
                                        onLoad={() => setIsLoading(false)}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VoceraResultEmbed