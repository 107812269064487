import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Form, Input, Dropdown, Icon, Grid,Message } from 'semantic-ui-react';
import styles from "./DataConnections.module.css";
import dataconnection from '../../../api/DataConnection/dataconnection';
import { DataContext } from './DataProvider';
import "./CustomModal.css"; // Custom CSS file for modal styling

const DataLayerModal = ({ open, onClose, onOpen, setDatabases, initFormdata,setLoading ,setinitFormdata}) => {
  const { data, loaddata, connections, updateConeection } = useContext(DataContext);
  const [formData, setFormData] = useState(initFormdata);
  const [columns, setColumns] = useState(initFormdata?.coloumns ?? [ { name: '', type: '' } ]);
  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  const customStyle = {
	fontSize: "18px",
	marginTop: "-12px",
	fontFamily: "sans-serif"
  };
  // MySQL column types for the dropdown
  const columnTypes = [
    { key: 'string', text: 'STRING', value: 'STRING' },
    { key: 'number', text: 'NUMBER', value: 'NUMBER' },
    { key: 'date', text: 'DATE', value: 'DATE' },
    { key: 'boolean', text: 'BOOLEAN', value: 'BOOLEAN' },
    { key: 'array', text: 'ARRAY', value: 'ARRAY' },
	{ key: 'object', text: 'OBJECT', value: 'OBJECT' },
  ];

  const onCloses = () => {	
	handleReset();
	onClose();
  }
  // Handle column input change
  const handleColumnChange = (index, field, value) => {
    const updatedColumns = [...columns];
    updatedColumns[index][field] = value;
    setColumns(updatedColumns);
  };

  // Add a new column
  const handleAddColumn = () => {
    setColumns([...columns, { name: '', type: '' }]);
  };

  // Remove a column
  const handleRemoveColumn = (index) => {
    const updatedColumns = columns.filter((_, i) => i !== index);
    setColumns(updatedColumns);
  };

  // Handle form reset
  const handleReset = () => {
    setFormData({});
	setinitFormdata({});
    setColumns([ { name: '', type: '' } ]);
  };

  // Handle form submit
  const handleSubmit = () => {
	let dblisr = connections;
	
	// Clear the previous error message
	setErrorMessage("");
	updateConeection(undefined);
	setLoading(true);
	// Validate table name
	if (!formData.tablename || formData.tablename.trim() === "") {
		setErrorMessage("Table name cannot be empty!");
		return;
	}

	// Validate columns
	for (const column of columns) {
		if (!column.name || column.name.trim() === "") {
			setErrorMessage("Column name cannot be empty!");
			return;
		}
		if (!column.type || column.type.trim() === "") {
			setErrorMessage("Column type cannot be empty!");
			return;
		}
	}

	// Prepare the JSON payload
	let payload = {
		id:formData.id ?? null,
		tableName: formData.tablename, // Table name from the form
		columns: columns.map((column) => ({
		  name: column.name, // Column name
		  type: column.type, // Column type
		})),
	};
    if (!formData.id ) {
      dataconnection.addConnection(payload).then((res) => {
		loaddata();
      });
    } else {
      dataconnection.updateConnection(payload).then((res) => {
        setDatabases(res.data);
        updateConeection(res.data);
        handleReset();
      });
    }
    onCloses();
	
  };

  return (
    <Modal open={open} onClose={onCloses} className="custom-modal-layer">
      <Modal.Header className="custom-modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span style={customStyle}>Add Database</span>
		<Icon name="close" onClick={onClose} style={{ cursor: 'pointer' }} />
        
      </Modal.Header>
		<Modal.Content className="contentContainer">
			{/* Display error message */}
			{errorMessage && (
			<Message error>
				<Message.Header>Validation Error</Message.Header>
				<p>{errorMessage}</p>
			</Message>
			)}
			<Form>
			{/* Table Name */}
			<Form.Field style={{ padding: '20px !important' }}>
				<div className={styles.btnAdj} style={{ padding: '20px !important' }}>
					<label>Table Name:</label>
					<div
					style={{ cursor: "pointer" }}
						onClick={handleAddColumn}
						>
						<Icon name="plus" /> Add Column
					</div>
				</div>

				<Input
				placeholder="Enter Table Name"
				name="tablename"
				value={formData.tablename}
				onChange={(e) => setFormData({ ...formData, tablename: e.target.value })}
				className={styles.inputField}
				style={{ width: '100%', borderBottom: '1px solid #ccc' }} // Ensure full width of the input field
				/>
				
			</Form.Field>
			{/* Columns Section */}
			{columns.map((column, index) => (
				<div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
					<Input
						placeholder="Column Name"
						name={`column-name-${index}`}
						value={column.name}
						onChange={(e) => handleColumnChange(index, 'name', e.target.value)}
						className={styles.inputField}
					/>
					<Dropdown
						placeholder="Column Type"
						fluid
						selection
						options={columnTypes}
						value={column.type}
						onChange={(e, { value }) => handleColumnChange(index, 'type', value)}
					/>
					{columns.length > 1 && (
						<div
						icon
						color="red"
						onClick={() => handleRemoveColumn(index)}
						>
						<Icon name="minus" color="red" style={{cursor: "pointer"}}/>
						</div>
					)}
				</div>
			))}
			</Form>
		</Modal.Content>
		<Modal.Actions style={{ padding: '20px' }}>
        
        {/* Action Buttons */}
        <Button color="blue" onClick={handleSubmit} className={`btn linear-gradient ${styles.button}`} > 
          Save Database
        </Button>
      </Modal.Actions>
    </Modal>
  );
};


export default DataLayerModal;
