import React from "react";
import { Modal, Button } from "semantic-ui-react";
import "./CustomModal.css"; // Custom CSS file for modal styling

const ConfirmationModal = ({ open, onClose, onConfirm }) => {
  return (
    <Modal open={open} onClose={onClose} size="small" className="custom-modal">
      <Modal.Content>
        <button type="button" class="close linear-gradient" onClick={onClose} data-dismiss="modal" aria-label="Close">X</button>
        <div class="modal-header"><h5 class="modal-title">Confirm your action</h5></div>
        <p className="custom-modal-content">Are you sure you want to delete?</p>
      </Modal.Content>
      <div class="modal-footer">
          <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={onClose} data-dismiss="modal" ><span>No</span></button>
          <button type="button" onClick={onConfirm} className="btn linear-gradient yr-submitbtn btn-sm" >Yes</button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
