import axios from "../config/axios";
import config from "../config/index";
const DatalayerandTooling = {
    getNewturboTool: (params) => {
        let urlparams = 'workspace_id='+params.workspace_id+'&limit=10&offset=0'
        return axios
            .get(`${config.apiBaseURL2}tools?${urlparams}`, params)
            .then((resp) => {
            return resp.data;
        })
        .catch((err) => console.log(err));
    },
    editNewturboTool: (params) => {
        return axios
            .patch(`${config.apiBaseURL2}tools/${params.id}`, params)
            .then((resp) => {
                return resp.data;
            })
            .catch((err) => console.log(err));
    },
    saveNewturboTool: (params) => {
        return axios
        .post(`${config.apiBaseURL2}tools`, params)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => console.log(err));
    },
    deleteNewturboTool: (id) => {
        return axios
        .delete(`${config.apiBaseURL2}tools/${id}`)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => console.log(err));
    },


}
export default DatalayerandTooling;
